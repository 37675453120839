// 引入路由偶
import router from './index'
import uni from "@/services/UNIService.js"

import { Message } from 'element-ui';



// 进入路由前方法勾子
router.beforeEach((to, from, next) => {
  // console.log(to, '前置第一个参数')
  // console.log(from, '前置第二个参数')
  // console.log(next, '前置第三个参数')


  // if(!uni.getStorageSync('userInfo')){
  if(!uni.getStorageSync('nickName')){
      // console.log('userAuth',(User.checkUserAuth()))

        if(to.matched.length>0 && !to.matched.some(record=>record.meta.requiresAuth)){

          // 当前用户未登录;且要跳转的路由无需验证则直接进入
          next();
         
        }else{
          
          if(to.path!='/login'){
            // 当前用户未登录;且要跳转的路由页面有访问限制,跳回登录页面; 
            Message.error('账号未登录');
          }

          // console.log(from);
          next({
            path:'/login'
          });


        }

  }else{
      // 要跳转到的路径
      // console.log(to)
      // 来源路径
      // console.log(from.fullPath)

      // if(!store.state.permission.permissionList){

      //   store.dispatch('FETCH_PERMISSION').then(()=>{

      //     let permissionList=store.state.permission.permissionList;
      //       // console.log(permissionList);
      //       // permissionList.push('404');
      //       if(!permissionList.includes(to.name)){
      //           Notification({
      //             'title':'提示',
      //             type: 'warning',
      //             message: '暂无权限'
      //           });
      //           next(from.fullPath);
      //           // next({
      //           //   path:'404'
      //           // })   
      //       }else{
      //         next({
      //           path:to.path
      //         })
      //       }
      //       // console.log(store.state.permission.permissionList);
      //   });

      // }else{
        
          if(to.path=='/login'){

            // 用户已登录;且权限以获取;想再次进入登录页则跳转到上一路径
            next(from.fullPath);

          }else{
            
            // // 用户已登录;权限已获取;继续判断用户是否有权限进入到当前页面,如果有则直接进去;如果没有则提示没有权限
            // let permissionList=store.state.permission.permissionList;
            // // 本地放入404页面受限制
            // // permissionList.push('404');

            // if(!permissionList.includes(to.name)){
            //     console.log(to.name)
            //     Notification({
            //       'title':'提示',
            //       type: 'warning',
            //       message: '暂无权限1'
            //     });

            //     next(from.fullPath);
            //     // next({
            //     //   path:'404'
            //     // })

            // }else{
              next();
            // }

        }

      // }



  }

});

router.afterEach((to,from) => {
  window.scrollTo(0,0);
  // 或
  // window.scroll(0, 0);
});
