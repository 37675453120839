<template>
    <div>
        <layoutHeader :scrollTop="scrollTop"></layoutHeader>
        <router-view/>
    </div>
</template>

<script>
import layoutHeader from '@/components/layout/header.vue'
export default{
    components:{
        layoutHeader
    },
    data(){
        return {
            scrollTop:0
        }
    },
    mounted() {
        window.addEventListener('scroll', this.windowScroll);
    },
    methods: {
        windowScroll() {
            // 滚动条距离页面顶部的距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // console.log(scrollTop);
            this.scrollTop=scrollTop;
        }
    },
    beforeDestroy() {
 	    window.removeEventListener('scroll', this.windowScroll);
    }
}
</script>

<style lang="scss" scoped>

</style>