import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/layout.vue'
import Home from '@/views/home/index.vue'
import Login from '@/views/login/login.vue'

import My from '@/views/my/index.vue'
import notFound from '@/views/errorPages/404.vue'

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(VueRouter)

const routes = [
  

  {
    path:"/",
    component:Layout,
    name:'layout',
    redirect:'home',
    meta:{
      title:'首页'
    },
    children:[
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta:{
          title:"主页"
        }
      },
      {
        path: 'mall',
        name: 'mall',
        component: () => import(/* webpackChunkName: "about" */ '../views/mall/index.vue'),
        meta:{
          title:"科技商城"
        }
      },
      {
        path: 'mall/pdtDetail',
        name: 'pdtDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/mall/detail.vue'),
        meta:{
          title:"产品详情"
        }
      },
      {
        path: 'coupon',
        name: 'coupon',
        component: () => import(/* webpackChunkName: "about" */ '../views/coupon/index.vue'),
        meta:{
          title:"创新劵"
        },
      },
      {
        path: 'coupon/institutionSettled',
        name: 'institutionSettled',
        component: () => import(/* webpackChunkName: "about" */ '../views/coupon/institutionSettled.vue'),
        meta:{
          title:"机构入驻"
        },
      },
      {
        path: 'coupon/applyCoupon',
        name: 'applyCoupon',
        component: () => import(/* webpackChunkName: "about" */ '../views/coupon/applyCoupon.vue'),
        meta:{
          title:"我要申领"
        },
      },
      {
        path: 'news',
        name: 'news',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue'),
        meta:{
          title:"新闻公告"
        }
      },{
        path: 'news/detail',
        name: 'newDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/detail.vue'),
        meta:{
          title:"新闻详情"
        }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/contact/index.vue'),
        meta:{
          title:"联系我们"
        }
      },
      {
        path: 'my',
        name: 'my',
        component: My,
        redirect:'myOrders',
        meta:{
          title:"我的",
          requiresAuth:true,
        },
        children:[
          {
            path: 'myOrders',
            name: 'myOrders',
            component: () => import('@/views/my/module/myOrders.vue'),
            meta:{
              title:"我的订单"
            },
          },{
            path: 'myCoupons',
            name: 'myCoupons',
            component: () => import('@/views/my/module/myCoupons.vue'),
            meta:{
              title:"我的创新劵"
            },
          },{
            path: 'myAddress',
            name: 'myAddress',
            component: () => import('@/views/my/module/myAddress.vue'),
            meta:{
              title:"地址管理"
            },
          },{
            path: 'feedback',
            name: 'feedback',
            component: () => import('@/views/my/module/feedback.vue'),
            meta:{
              title:"意见反馈"
            },
          },{
            path: 'costomerService',
            name: 'costomerService',
            component: () => import('@/views/my/module/costomerService.vue'),
            meta:{
              title:"客服热线"
            },
          },
          
        ]
      },
    ]
  },
  {
    path:"/login",
    component:Login,
    name:'login',
    meta:{
      title:'登录'
    },
  }
  ,{
    path:'*',
    component:notFound,
    name:'404'
  }
]





const router = new VueRouter({
  routes
})

export default router
