import  http from "../common/Request"
const UNIService = {
//   async doGetUserCard (userId,groupId) {
//     var res = await http({
//       url: "userCard_doGetUserCard.do",
//       method: "POST",
//       data:{
//         userId:userId,
//         groupId:groupId
//       }
//     })
//     return res.data
//   },

  setStorageSync(key,val){
    return sessionStorage.setItem(key,val);
  },
  getStorageSync(key){
    return sessionStorage.getItem(key);
  },
  removeStorageSync(key){
    return sessionStorage.removeItem(key);
  },
  clearStorageSync(){
    return  sessionStorage.clear();
  }


  
}

export default UNIService
