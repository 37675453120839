export default {
	data() {
		return {
			pagePlugin: {
				api: null,
				options: null
			}, //api是调用函数的function，options参数可先采用array的格式，然后通过apply方法传给函数
			pageList: [], //数据源
			currentPage: 1, //加载数据的页数
			pageSize: 6, //每一页加载数据的个数
			loadingStatus: 'more',
			showIcon:true,
			total:0,
			loadText: {
				loadmore: '轻轻上拉',
				loading: '努力加载中',
				nomore: '没有更多数据了'
			}
		}
	},
	onReady() {

	},
	onReachBottom() { //拉到页面底部调用的方法
		console.log("paging onReachBottom...loadingStatus:" + this.loadingStatus );
		this.doNextPage();
	},
	methods: {
		async initializePageList() { //初始化pageList
			// console.log("initializePageList...");
			this.currentPage = 1;
			this.loadingStatus = '';
			this.pageList = [];
			await this.doPageList();
		},
		async doPageList() { //调用接口
			// console.log(this.loadingStatus)
			this.loadingStatus = 'loading';
			// this.pagePlugin.options.splice(this.pagePlugin.options.length - 2, 1, this.currentPage); //替换currentPage
			console.log('options',this.pagePlugin.options);
			var res = await this.pagePlugin.api.apply(null, this.pagePlugin.options); //利用apply方法，将数组直接当做参数传给函数
			
			console.log(res)
			
			if (res.status != 1) {
				this.loadingStatus = 'nomore';
				return;
			}

			this.total=res.total;

			if (res.morePageCount <= 0) {
				this.loadingStatus = 'nomore';
			} else {
				this.loadingStatus = 'more';
			}
			if(res.data.length==0) {
				this.showIcon = false;
				this.loadingStatus = 'nomore';
			}

			// console.log(res);
			if(typeof this.dataFilter != 'undefined') {
				res.data = this.dataFilter(res.data);
			}

			// this.pageList = this.pageList.concat(res.data);
			this.pageList = res.data;

			if(typeof this.loaded != 'undefined') {
				this.loaded();
			}

		},
		doNextPage(){
			if (this.loadingStatus == "more") {
				this.currentPage = this.currentPage + 1;
				this.doPageList();
			}
		}
	},


}
