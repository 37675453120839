import UserService from '../services/UserService';
import locationUtil from "../common/LocationUtil";
import uni from "../services/UNIService"

export default {
	data() {
		return {
			latitude: null,
			longitude: null,
			isIos: true,
			userAuth:false,
			bindGroup:null,
			gradeNum:1
		}
	},
	methods: {
		async login() { //登录，获取用户信息
			var res = await UserService.login();
			return res;
		},
		async getLocation(e) {
			// #ifdef H5
			this.latitude = 31.95848655938315;
			this.longitude = 118.84658714280226;
			return {
				latitude: 31.95848655938315,
				longitude: 118.84658714280226
			}
			// #endif
			let [error, res] = await uni.getLocation({
				type: 'gcj02'
			});
			console.log(error+",getLocation");
			console.log(res);
			if (res && res.errMsg == 'getLocation:ok') {
				console.log('获取位置成功!');
				var bd09Point = locationUtil.gcj02tobd09(res.longitude, res.latitude);
				this.latitude = bd09Point.latitude;
				this.longitude = bd09Point.longitude;
				console.log(bd09Point);
			}
			return {
				latitude: this.latitude,
				longitude: this.longitude
			}
		},
		async checkUserAuth() {
			this.gradeNum = 1;
			let nickName = uni.getStorageSync('nickName');
			if (!nickName || nickName == '') {
				this.userAuth = false;
				return false
			}
			this.gradeNum = await UserService.getGradeNum();
			this.userAuth = true;
			return true
		},
		async checkUserAndGetSessionKey() {
			var userAuth = await this.checkUserAuth();
			console.log("userAuth:"+userAuth);
			if(!userAuth) {
				await UserService.refreshSessionKey();
			}
			return this.checkUserAuth();
		},
		async checkBind() {
			this.bindGroup = await UserService.checkBind();
		},
		async getUserInfo(e, loginCallback) {
			let userInfo = {}; //用户信息对象
			let encryptedData = ''; //包括敏感数据在内的完整用户信息的加密数据
			let iv = ''; //加密算法的初始向量(只存在小程序)
			var that = this;
			// #ifdef MP-WEIXIN
			if (e.detail.errMsg != 'getUserInfo:ok') {
				return false
			}
			userInfo = e.detail.userInfo;
			encryptedData = e.detail.encryptedData;
			iv = e.detail.iv;
			userInfo['encryptedData'] = encryptedData;
			userInfo['iv'] = iv;
			await this.updateUser(userInfo, loginCallback);
			// #endif
			// #ifdef APP-PLUS
			console.log("getUserInfo-----------");

			//转到登录界面后再返回

			uni.login({
				provider: 'weixin',
				success: function (loginRes) {
					console.log(loginRes);
					// 获取用户信息
					uni.getUserInfo({
						provider: 'weixin',
						success: function (infoRes) {
							console.log(infoRes.userInfo);
							userInfo = infoRes.userInfo;
							encryptedData = infoRes.encryptedData;
							that.updateUser(userInfo, loginCallback);
						}
					});
				},
				fail:function (fail) {
					loginCallback(false);
					console.log("fail-----------------");
					console.log(fail);
				}
			});
			// #endif
		},
		async updateUser(userInfo, loginCallback) {
			var that = this;
			console.log("updateUser...");

			userInfo['headimgurl'] = userInfo['avatarUrl'];
			userInfo['nickname'] = userInfo['nickName'];
			userInfo['sex'] = userInfo['gender'];
			userInfo['openid'] = userInfo['openId'];
			userInfo['unionid'] = userInfo['unionId'];
			delete userInfo.avatarUrl;
			delete userInfo.nickName;
			delete userInfo.gender;
			delete userInfo.unionId;
			delete userInfo.openId;

			// #ifdef MP-WEIXIN

			console.log("session_key 未过期，并且在本生命周期一直有效");
			var res = null;
			//session_key 未过期，并且在本生命周期一直有效
			res = await UserService.doUpdateByWXA(userInfo);
			if(!res.data.imageurl) {
				res.data.imageurl = {};
			}
			that.setUserStorageSync(res.data.id, res.data.nickName, res.data.token, res.data.unionid, res.data.gradeNum, res.data.imageUrl.middleImg);
			loginCallback(true, res.data);

			// #endif

			// #ifdef APP-PLUS
			res = await UserService.doUpdateByAPP(userInfo);
			if (res.status != 1) {
				return false
			}
			if(!res.data.imageurl) {
				res.data.imageurl = {};
			}
			this.setUserStorageSync(res.data.id, res.data.nickName, res.data.token, res.data.unionid, res.data.gradeNum, res.data.imageUrl.middleImg);
			loginCallback(true, res.data);
			// #endif

		},
		async getPhoneNumber(e) {
			// #ifdef MP-WEIXIN
			if (e.detail.errMsg == "getPhoneNumber:ok") {
				var res = await UserService.doGetPhoneNumber(e.detail.encryptedData, e.detail.iv);
				if (res.status) {
					return res.data.phoneNumber;
				}
			}
			// #endif
			// #ifdef APP-PLUS
			console.log('getPhoneNumber.....');
			await uni.login();
			var res = await wx.getUserInfo();
			console.log(res);
			// #endif
			return {
				status: 1,
				data: {
					phoneNumber: 13888888888
				}
			}
		},
		async openSetting(e) {
			// #ifdef MP-WEIXIN
			if (!e.detail.authSetting['scope.userLocation']) {
				//二次授权结果
				console.log('拒绝。。。。。。');
				return false;
			}
			return true;
			// #endif
			// #ifdef APP-PLUS
			console.log('is app.......')

			// #endif

		},
		setUserStorageSync(userId, nickName, token, unionId, gradeNum, iconUrl) { //缓存用户信息
			if(nickName) {
				this.userAuth = true;
			}
			uni.setStorageSync('userId', userId);
			uni.setStorageSync('nickName', nickName);
			uni.setStorageSync('token', token);
			uni.setStorageSync('unionId', unionId);
			uni.setStorageSync('gradeNum', gradeNum);
			uni.setStorageSync('iconUrl', iconUrl);
		},
		getCaptainUserId() {
			var captainUserDate = uni.getStorageSync("captainUserDate");
			console.log("captainUserDate:"+captainUserDate);
			if(captainUserDate){
				var second = (new Date().getTime() - captainUserDate)/1000;
				console.log("second："+second);
				if(second >= (24*60)) {
					uni.removeStorageSync("captainUserDate");
					uni.removeStorageSync("captainUserId");
					return null;
				} else {
					return uni.getStorageSync("captainUserId");
				}
			}
		}
	}
}
