<template>
    <div class="login-wrap">
        <div class="login-body-box" :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/login_bg.jpg)',backgroundPosition: 'center center',backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
            
            <div class="login-form-box">
                <div class="login-logo"><img :src="require('../../assets/img/login/logo.png')" alt="logo"></div>
                <div class="qrcode" >
                    <img :src="qrcodeUrl" v-if="qrcodeUrl" alt="登录二维码">
                </div>
                <div class="qrcode-tips">
                    请使用微信扫码登录
                </div>
               <!--  <div class="login-form">
                    <el-form label-position="top" label-width="80px" :model="form">
                        <el-form-item label="账 号">
                            <el-input  v-model="form.name" autocomplete="off" placeholder="账号"></el-input>
                        </el-form-item>
                        <el-form-item label="密 码">
                            <el-input  type="password" v-model="form.password" autocomplete="off" placeholder="密码"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="login-tips clearfix">
                    <el-button type="text">忘记密码?</el-button>
                    <el-button type="text" class="right">注册账号</el-button>
                </div>
                <div class="login-tools">
                    <el-button type="primary" class="login-btn">登 录</el-button> 
                </div> -->
            </div>

          

        </div>
    </div>
</template>

<script>
    import UserService from '@/services/UserService'
    import uni from '@/services/UNIService.js'

    export default{
        data(){
            return {
                loginKey:'',
                qrcodeUrl:'',
                timer:null,
                form: {
                    name: '',
                    password: '',
                }
            }
        },
        created(){
            this.init();
        },
        methods:{
            async init(){
                let res= await UserService.doScanQRCodeLogin();

                if(res.status==1){
                    this.qrcodeUrl=res.data.qrcodeUrl;
                    this.loginKey=res.data.loginKey;

                    // console.log(res.data.loginKey);
                    this.timer = setInterval(()=>{
                        this.doGetQRCodeLoginResult(res.data.loginKey)
                    }, 1000);
                    
                }
                // console.log(res)
            },

            async doGetQRCodeLoginResult(loginKey){
                
                let res= await UserService.doGetQRCodeLoginResult(loginKey);
                // console.log(res);

                if(res.status==1){

                    if(res.data){
                        let userInfo=res.data;
                        uni.setStorageSync('nickName',userInfo.nickName);
                        uni.setStorageSync('unionId', userInfo.unionid);
                        uni.setStorageSync('token', userInfo.token);
                        uni.setStorageSync('userId', userInfo.id);
                        uni.setStorageSync('userInfo', JSON.stringify(userInfo));
                        uni.setStorageSync('avatar', userInfo.imageUrl.smallImg);
                        clearInterval(this.timer);
                        this.timer = null;
                        this.$router.push({
                            path:'/my/myOrders',
                        });
                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        });
                    }
                }
            }
            
        },
        beforeDestroy(){
            clearInterval(this.timer);
            this.timer = null;
        }
    }
</script>

<style lang="scss" scoped>
    .login-wrap{
        width: 100%;
        height:100vh;
        .login-body-box{
            position: relative;
            width: 100%;
            height:100vh;
            min-width:1100px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .login-form-box{
                width:360px;
                height:500px;
                border-radius:10px;
                background:#fff;
                margin-right: 160px;

                .login-logo{
                    width:196px;
                    height:59px;
                    margin:  40px auto 0;

                    img{
                         width:196px;
                        height:59px;
                    }
                }

                .qrcode{
                    width:240px;
                    height:240px;
                    margin:40px auto 30px;

                    img{
                        width:240px;
                        height:240px;
                    }
                }
                .qrcode-tips{
                    height:48px;
                    line-height: 48px;
                    font-size:18px;
                    color:#666;
                }



                .login-form{
                    margin-top: 20px;
                    padding:0 30px;
                    .el-form-item{
                        text-align: left;
                        padding-bottom:0;
                    }
                }

                .login-tips{
                    height:40px;
                    padding:0 30px;
                    text-align: left;
                }

                .login-tools{
                    margin-top: 30px;
                     padding:0 30px;
                     .login-btn{
                         width: 100%;
                     }

                }

                
            }

            .regist-form-box{
                width:360px;
                height:500px;
                border-radius:10px;
                background:#fff;
                margin-right: 160px;

                .login-logo{
                    width:196px;
                    height:59px;
                    margin:  40px auto 0;

                    img{
                         width:196px;
                        height:59px;
                    }
                }

                .login-form{
                    margin-top: 20px;
                    padding:0 30px;
                    .el-form-item{
                        text-align: left;
                        padding-bottom:0;
                    }
                }

                .login-tips{
                    height:40px;
                    padding:0 30px;
                    text-align: left;
                }

                .login-tools{
                    margin-top: 30px;
                     padding:0 30px;
                     .login-btn{
                         width: 100%;
                     }

                }

                
            }
        }
    }
</style>