/* eslint-disable semi-spacing,space-before-function-paren,quotes */
import http from '../common/Request'
const DataCenterService = {
     /**
   * 领劵分布
   * @param {Object} val
   */
	async doStatisticalTotalAmountGroupType(couponLog) {
      var res = await http({
        url: "openCoupon_doStatisticAlmountGroupType.do",
        method: "POST",
  	  data:{
          couponLog:couponLog
  	  }
       
      })
      return res.data
    },
	
	/**
	* 领劵分布详情
	* @param {Object} val
	*/
	async doStatisticalGuoupDistributed(couponLog) {
	   var res = await http({
	     url: "openCoupon_doDrawAlmountGroupType.do",
	     method: "POST",
	  	  data:{
	       couponLog:couponLog
	  	  }
	    
	   })
	   return res.data
	 },
  
  /**
   * 用卷商品排行
   * @param {Object} val
   */
  async doStatisticalReducePriceAmountGroupGoods(orderSearchModel) {
    var res = await http({
      url: "openOrder_doStatisticalReducePriceAmountGroupGoods.do",
      method: "POST",
     data:{
	   orderSearchModel:orderSearchModel
     }
    })
    return res.data
  },
  
  /**
   *合同总额
   * @param {Object} orderSearchModel
   */
  async doSumTotalPrice(orderSearchModel) {
    var res = await http({
      url: "openOrder_doSumTotalPrice.do",
      method: "POST",
     data:{
     		   orderSearchModel:orderSearchModel
     }
    })
    return res.data
  },
  
  /**
   *合同总额明细
   * @param {Object} orderSearchModel
   */
  async doSumTotalPriceDetail(orderSearchModel) {
    var res = await http({
      url: "openOrder_doStatisticalTotalAmountGroup.do",
      method: "POST",
     data:{
     		   orderSearchModel:orderSearchModel
     }
    })
    return res.data
  },
  
 
  /**
   *领劵总额--废
   * @param {Object} orderSearchModel
   */
  async doStatisticalTotalAmount(orderSearchModel) {
    var res = await http({
      url: "openOrder_doStatisticalTotalAmount.do",
      method: "POST",
     data:{
     		   orderSearchModel:orderSearchModel
     }
    })
    return res.data
  },
  /**
   *领劵总额 - 新
   * @param {Object} orderSearchModel
   */
  async doGetConsumeAmount(couponLog) {
    var res = await http({
      url: "openCoupon_doGetConsumeAmount.do",
      // url: "openOrder_doStatisticalReducePrice.do",
      method: "POST",
     data:{
     		   couponLog:couponLog
     }
    })
    return res.data
  },
  /**用券总额
   * @param {Object} couponLog
   */
  async doGetConsumeCouponAmount(orderSearchModel) {
    var res = await http({
      url: "openOrder_doStatisticalReducePrice.do",
      method: "POST",
     data:{
     		   orderSearchModel:orderSearchModel
     }
    })
    return res.data
  },
  /**
   *用卷总额明细
   * @param {Object} orderSearchModel
   */
  async doGetConsumeAmountDetail(orderSearchModel) {
    var res = await http({
      url: "openOrder_doStatisticalAmountAccountGroup.do",
      method: "POST",
     data:{
     		   orderSearchModel:orderSearchModel
     }
    })
    return res.data
  },
  /**
   *获取商品分类的商家数量
   * @param {Object} orderSearchModel
   */
   async doGetCategoryGroupCount(search) {
    var res = await http({
      url: "goods/goods_doGoodsCategoryGroupCount.do",
      method: "POST",
     data:{
        search:search   
        }
    })
    return res.data
  },
  
  /**
   *分类企业详情
   * @param {Object} orderSearchModel
   */
   async doGetCategoryGroupDetail(search) {
    var res = await http({
      url: "goods/goods_doGoodsCategoryGroup.do",
      method: "POST",
     data:{
        search:search   
        }
    })
    return res.data
  },
}
export default DataCenterService
