/* eslint-disable semi-spacing,space-before-function-paren,quotes */
import http from '../common/Request'
import config from '../common/Config.js';
const GoodsService = {
	//获取店铺商品分类
	async doSearchTreeByGroup(groupId) {
		var res = await http({
			url: "goods/category_doSearchGoodsCategoryByGroupId.do",
			method: "POST",
			data: {
				groupId: groupId
			}
		})
		return JSON.parse(res.data.data);
	},
	async doSearchGroupFirstCaregory(groupId) {
		var res = await http({
			url: "goods/category_doSearchGroupFirstCaregory.do",
			method: "POST",
			data: {
				groupId: groupId,
				showType: config.getShowType()
			}
		})
		return res.data.data
	},
	async doSearchCategoryTreeJsonByPid(pid) {
		var res = await http({
			url: "goods/category_doSearchCategoryTreeJsonByPid.do",
			method: "POST",
			data: {
				pid: pid,
				isShow: 1,
				showType: config.getShowType()
			}
		})
		return JSON.parse(res.data.data);
	},
	async doSearchByPid(pid) {
		var res = await http({
			url: "goods/category_doSearchByPid.do",
			method: "POST",
			data: {
				pid: pid,
				isShow: 1
			}
		})
		return res.data.data
	},
	async doGetByGoodsCategoryId(groupId, goodsCategoryId) { //获取收款商品id
		var res = await http({
			url: "goods/goods_doGetByGoodsCategoryId.do",
			data: {
				groupId: groupId,
				goodsCategoryId: goodsCategoryId
			}
		});
		return res.data
	},
	async doGetCategory(categoryId) {
		var res = await http({
			url: "goods/category_doGet.do",
			data: {
				id: categoryId
			}
		})
		return res.data
	},
	async doSearchGoodsShopCategory(groupId) { //查询店铺的商品分类
		var res = await http({
			url: "goods/shopcategory_doSearchGoodsShopCategory.do",
			data: {
				groupId: groupId
			}
		});
		return res.data
	},
	async doPageBySearchModel(search, page, count) { //获取分类商品groupId,shopCategoryId,deleteStatus,sortExp,page,count
		if (search) {
			search.reviewStatus = 1;
			search.sellStatus = 1;
		}
		// search.reviewStatus=1
		var res = await http({
			url: "goods/goods_doPage.do",
			data: {
				search: search,
				page: page,
				count: count
			}
		});
		return res.data
	},
	async doPageBySubjectId(status, goodsSubjectId, sortExp, page, count) { //获取分类商品groupId,shopCategoryId,deleteStatus,sortExp,page,count
		var res = await http({
			url: "goods/goods_doPage.do",
			data: {
				search: {
					status: status,
					goodsSubjectId: goodsSubjectId,
					sellStatus: 1,
					sortExp: sortExp,
					showType: config.getShowType()
				},
				page: page,
				count: count
			}
		});
		return res.data
	},
	async doPage(status, tradingAreaId, categoryId, sortExp, page, count) { //获取分类商品groupId,shopCategoryId,deleteStatus,sortExp,page,count
		var res = await http({
			url: "goods/goods_doPage.do",
			data: {
				search: {
					status: status,
					tradingAreaId: tradingAreaId,
					categoryId: categoryId,
					sellStatus: 1,
					reviewStatus:1,
					sortExp: sortExp
				},
				page: page,
				count: count
			}
		});
		return res.data
	},
	async doPageByGroup(status, tradingAreaId, groupId, categoryId, sortExp, page, count) { //获取分类商品groupId,shopCategoryId,deleteStatus,sortExp,page,count
		var res = await http({
			url: "goods/goods_doPage.do",
			data: {
				search: {
					status: status,
					tradingAreaId: tradingAreaId,
					groupId: groupId,
					categoryId: categoryId,
					sellStatus: 1,
					sortExp: sortExp,
					showType: config.getShowType()
				},
				page: page,
				count: count
			}
		});
		return res.data
	},
	async doPageNearGoods(tradingAreaNo, location, radius, distance, page, count) { //获取分类商品
		var res = await http({
			url: "goods/goods_doPageNearGoods.do",
			data: {
				tradingAreaNo: tradingAreaNo,
				location: location,
				radius: radius,
				distance: distance,
				page: page,
				count: count
			}
		});
		return res.data
	},
	async doPageHotNearGoods(ptradingAreaId, location, categoryId, radius, distance, page, count) { //获取分类商品
		console.log({
			"tradingAreaNo": ptradingAreaId
		});
		var res = await http({
			url: "goods/goods_doPageNearGoods.do",
			data: {
				ptradingAreaId: ptradingAreaId,
				location: location,
				categoryId: categoryId,
				radius: radius,
				distance: distance,
				page: page,
				count: count
			}
		});
		return res.data
	},
	async doSearchGoodsSubjects(shopId) { //获取分类商品
		var res = await http({
			url: "goods/goodssubject_doSearch.do",
			data: {
				shopId: shopId
			}
		});
		return res.data.data
	},
	/* +++++++++++++++++++++ */
	async doGet(id) { // 获取修改商品信息
		var res = await http({
			url: "goods/goods_doGet.do",
			data: {
				id: id
			}
		});
		return res.data
	},
    async doGetSimple(id) { // 获取修改商品信息
        var res = await http({
            url: "goods/goods_doGetSimple.do",
            data: {
                id: id
            }
        });
        return res.data
    },
	async doPage(categoryId, groupId, sellStatus, reviewStatus, sortExp, titleKeyword, page, count) {
		var res = await http({
			url: "goods/goods_doPage.do",
			data: {
				search: {
					categoryId: categoryId,
					groupId: groupId,
					sellStatus: sellStatus,
					reviewStatus: reviewStatus,
					sortExp: sortExp,
					titleKeyword: titleKeyword
				},
				page: page,
				count: count
			}
		})
		return res.data
	},
	async doSearchByCompany(groupId) {
		var res = await http({
			url: "goods/category_doSearchByCompany.do",
			data: {
				groupId: groupId
			}
		})
		return res.data
	},
	async doGetCount(categoryId, groupId, sellStatus, reviewStatus, sortExp, titleKeyword) {
		var res = await http({
			url: "goods/goods_doGetCount.do",
			data: {
				search: {
					categoryId: categoryId,
					groupId: groupId,
					sellStatus: sellStatus,
					reviewStatus: reviewStatus,
					sortExp: sortExp,
					titleKeyword: titleKeyword
				}
			}
		});
		return res.data
	},
	async doAdd(goods) { // 修改商品信息
		var res = await http({
			url: "goods/goods_doAdd.do",
			data: goods,
			bindToken:true
		});
		return res.data
	},
	async doUpdate(goods) { // 提交修改商品信息
		var res = await http({
			url: "goods/goods_doUpdate.do",
			data: goods,
            bindToken:true
		});
		return res.data
	},
	async doDelete(id,loading) { // 删除商品
		var res = await http({
			url: "goods/goods_doDelete.do",
			data: {
				id: id
			},
			loading: loading
		});
		return res.data
	},
	async doChangeSellStatus(id,sellStatus,loading) { //修改上下架状态
		var res = await http({
			url: "goods/goods_doChangeSellStatus.do",
			data: {
				id: id,
				sellStatus: sellStatus
			},
			loading: loading
		});
		return res.data
	},
	async doSearchByCompany() { //获取订单信息
		var res = await http({
			url: "goods/category_doSearchByCompany.do"
		});
		return res.data;
	},
	async doSearchByCategoryIdFull(categoryId) { 
		var res = await http({
			url: "goods/propname_doSearchByCategoryIdFull.do",
			data:{
				categoryId:categoryId,
			}
		});
		return res.data;
	},
    async doSearch(search, ids) { //获取分类商品groupId,shopCategoryId,deleteStatus,sortExp,page,count
        if (search) {
            search.showType = config.getShowType();
        }
        var res = await http({
            url: "goods/goods_doSearch.do",
            data: {
                search: search,
                ids: ids
            }
        });
        return res.data
    },
    async doPageGoodsGroupRelation(param) {
        var res = await http({
            url: "goods/goods_doPageGoodsGroupRelation.do",
            data:param
        });
        return res.data;
    }
}
export default GoodsService
