<template>
 

     <div :class="[headPos=='fixed' ? 'header-fixed' : 'header-static']">
        <div class="head-wrapper">
            <div class="logo">
                <img :src="require(headPos=='fixed'?'../../assets/img/logo_1.png':'../../assets/img/logo_2.png')" alt="logo" width="108" height="42">
            </div>
            <div class="navs">
                <el-menu  
                    :text-color="headPos=='fixed'?'#999':'#fff'"
                    :active-text-color="headPos=='fixed'?'#0f71ea':'#fff'"
                    :default-active="currPath"
                    :router='true'
                    class="el-menu" mode="horizontal">
                    <el-menu-item  v-for="(item,index) in menus" :key="index" :index="item.url" @click="gotoRoute(item.url)">{{item.title}}</el-menu-item>
                </el-menu>
            </div>

            <div class="search">
                <el-input
                    size="small"
                    class="search-input"
                    placeholder=""
                    v-model="keyword">
                    <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
                    <i
                        class="el-icon-search el-input__icon"
                        slot="suffix"
                        @click="serarhKeyword">
                    </i>
                </el-input>
            </div>

            <div class="user-info">
                <div class="login-btn-box" v-if="!userAuth">
                    <el-dropdown placement="bottom">
                        <el-button type="text"> <i class="el-icon-s-custom"></i> 登录</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <router-link to="/login">
                                    <el-button type="text" > 用户登录</el-button>
                                </router-link>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <a href="http://www.suconnected.com/zhiguoguo/#/login" title="商家登录">商家登录</a>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <router-link to="/my/myOrders" v-else>
                    <div class="user-info-box" >
                        <div class="user-avatar">
                            <el-avatar :src="avatar"></el-avatar>
                        </div>
                        <div class="user-nickname">{{nickName}}</div>
                    </div>
                </router-link>
            </div>
        </div>
          
    </div>
</template>

<script>
import uni from "@/services/UNIService.js"
    export default{
        data(){

            return {
                headPos:'fixed',
                menus:[
                    {
                        title:"首页",url:"/home",index:1
                    },
                    {
                        title:"科技商城",url:"/mall",index:2
                    },
                    {
                        title:"创新劵",url:"/coupon",index:3
                    },
                    {
                        title:"新闻公告",url:"/news",index:3
                    },
                    {
                        title:"联系我们",url:"/contact",index:4
                    },
                ],
                currPath:'',
                keyword:'',
                userAuth:false,
                nickName:'',
                avatar:''
            }
        },

        props: {
            scrollTop:{
                type: Number,
                default() {
                    return 0;
                }
            }
		},

        watch: {
          
            $route: {
                immediate: true, // 一旦监听到路由的变化立即执行
                handler(to, from) {
                    if(to.name=='newDetail'){
                        this.currPath= '/news'
                    }else if(to.name=='pdtDetail'){
                         this.currPath= '/mall'
                    }else if(to.name=='institutionSettled'){
                         this.currPath= '/coupon'
                    }else if(to.name=='applyCoupon'){
                         this.currPath= '/coupon'
                    }else{
                        if(to.path=='/home'){
                            if(this.scrollTop){
                                this.headPos='fixed';
                            }else{
                                this.headPos='static';
                            }
                        }else{
                            this.headPos='fixed';
                        }
                         this.currPath= to.path
                    }
                    // console.log("监听路由：" , to);
                    // console.log("监听路由：" , from);

                    this.init()
                },
            },
            scrollTop: function (newValue) {
                if(this.currPath=='/home'){
                    if(this.scrollTop){
                        this.headPos='fixed';
                    }else{
                        this.headPos='static';
                    }
                }else{
                    this.headPos='fixed';
                }
            },

        },
        async created(){
            this.init()
        },

        methods:{
            async init(){
                this.userAuth=await this.checkUserAuth();
                if(this.userAuth){
                    this.nickName=uni.getStorageSync('nickName') || '昵称';
                    this.avatar=uni.getStorageSync('avatar') || require('../../assets/img/default_avatar.png');
                }
            },

            serarhKeyword(){
                if(this.keyword){
                    this.$router.push({
                        name:'mall',
                        params:{
                            keyword:this.keyword
                        }
                    });  


                    this.keyword='';

                }
            },
            gotoRoute(url){
                this.$router.push({
                    path:url
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header-fixed{
        position: fixed;
        top:0;
        width:100%;
        height:68px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        z-index: 99;
        background: #fff;
        
        .head-wrapper{
            width:1100px;
            margin:0 auto;
            padding-top:4px;
            display: flex;
            
            .logo{
               width: 160px;
               height: 60px;
                
                img{
                    margin-top: 9px;
                }
            }
            .navs{
                width:calc(100% - 500px);
                .el-menu.el-menu--horizontal {
                    border-bottom: none!important;
                    border-bottom-color:transparent!important;
                }
                .el-menu-item:hover{
                    background: #fff!important;
                }
                /deep/.el-menu-item{
                    font-size: 16px;
                    font-weight:800;
                    border-bottom: none!important;
                    border-bottom-color:transparent!important;
                }
                /deep/.el-menu-item.is-active{
                    border-bottom:none;
                    border-bottom-color:transparent;
                    background:transparent!important;
                }

                .el-menu-item.is-active:before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 4px;
                    background-color: #0f71ea;
                    background-image: -o-linear-gradient(224deg,#0f71ea 0,#0f71ea 100%);
                    background-image: linear-gradient(
                -134deg
                ,#0f71ea,#0f71ea);
                    // border-radius: 2px;
                    position: absolute;
                    left: 50%;
                    top: 50px;
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
                }
            }

            .search{
                line-height: 60px;

                .search-input{
                    width:134px;
                    
                    /deep/.el-input__inner{
                        border-radius: 32px;
                        background:#f5f5f5;
                    }
                    /deep/.el-icon-search{
                        cursor: pointer;
                    }

                    /deep/.el-icon-search:hover{
                        color:#409EFF;
                        font-weight: 600;
                    }
                }
            }

            .user-info{
                line-height:60px;
                margin-left:20px;
                
                .login-btn-box{
                    font-weight:800;
                    color:#999;
                    height:60px;
                    line-height:60px;
                    padding-left:40px;

                   .el-button{
                        color:#666;
                        padding:0 0;
                        height:60px;
                        line-height: 60px;
                        // line-height: px;
                        // font-weight:600;
                        /deep/span {
                            display: inline-block;
                            height: 24px;
                            line-height: 24px;
                            font-size: 16px;
                            vertical-align: middle;
                        }
                        .el-icon-s-custom{
                            // margin-right:px;
                            font-size: 24px;
                            color:#0f71ea;
                            vertical-align: middle;
                            margin-bottom: 4px;
                        }
                    } 
                    span{
                        margin-bottom: -4px;
                        
                    }
                }

                .user-info-box{
                    width: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .user-avatar{
                        padding-top:12px;
                        margin:0 20px 0 0;
                    }

                    .user-nickname{
                        width:140px;
                         height: 60px;
                        line-height: 50px;
                        font-size: 18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                
               
            }
        }

        
    }
    .header-static{
        position: absolute;
        top: 0;
        width:100%;
        height:68px;
        z-index: 99;
        background:transparent;
        
        .head-wrapper{
            width:1100px;
            margin:0 auto;
            // padding-top:10px;
            display: flex;
            
            .logo{
               width: 160px;
               height: 60px;
                
                img{
                    margin-top: 9px;
                }
            }
            .navs{
                width:calc(100% - 500px);
                .el-menu.el-menu--horizontal {
                    border-bottom: none;
                    background: transparent;
                }
                .el-menu-item:hover{
                    background:transparent!important;
                }
                .el-menu-item:active,.el-menu-item:visited{
                    background:transparent!important;
                }
                .el-menu-item:focus{
                    background:transparent!important;
                }
                /deep/.el-menu-item{
                    font-size: 16px;
                    font-weight:800;
                    border-bottom:none!important;
                     border-bottom-color:transparent!important;
                }
                /deep/.el-menu-item.is-active{
                    
                }

                .el-menu-item.is-active:before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 4px;
                    background-color: #fff;
                    background-image: -o-linear-gradient(224deg,#fff 0,#fff 100%);
                    background-image: linear-gradient(
                -134deg
                ,#fff,#fff);
                    // border-radius: 2px;
                    position: absolute;
                    left: 50%;
                    top: 50px;
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
                }
            }

            .search{
                line-height: 60px;

                .search-input{
                    width:134px;
                    /deep/.el-input__inner{
                        border-radius: 32px;
                        background: rgba(255,255,255,0.05);
                        color: #fff;
                    }
                }
            }

            .user-info{
                line-height:60px;
                margin-left:20px;
                
                .login-btn-box{
                    font-weight:800;
                    color:#fff;
                    height:60px;
                    line-height:60px;
                    padding-left:40px;

                   .el-button{
                        color:#fff;
                        font-size: 16px;
                        font-weight:800;

                        /deep/span {
                            display: inline-block;
                            height: 24px;
                            line-height: 24px;
                            font-size: 16px;
                            vertical-align: middle;
                        }
                        .el-icon-s-custom{
                            // margin-right:px;
                            font-size: 24px;
                            color:#fff;
                            vertical-align: middle;
                            margin-bottom: 4px;
                        }

                    } 
                    span{
                        margin-bottom: -4px;
                    }
                }

                .user-info-box{
                    width: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color:#fff;
                    .user-avatar{
                        padding-top:12px;
                        margin:0 20px 0 0;
                    }

                    .user-nickname{
                        height: 60px;
                        line-height: 50px;
                        font-size: 18px;
                    }
                }

                
               
            }
        }
    }
</style>