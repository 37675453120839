<template>
    <div class="banner-box">
        <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="(item,index) in banners" :key="index" >
                <div class="banner-item" :style="{  backgroundImage:'url(' + item.imgUrl + ')',backgroundPosition: 'center bottom',backgroundRepeat:'no-repeat',backgroundSize:'cover' }" @click="toPath2(3)"></div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
export default {
    name:'su-home-banner',
    data(){
        return {
            banners:[
                {imgUrl:'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/zhigg_web_bg1.png'},
            ],
        }
         
    }
}
</script>
<style lang="scss" scoped>
// banner
.banner-box{
    position:relative;
    width: 100%;
    margin:0 0 0;

    max-height: 540px;
    overflow: hidden;

    .el-carousel{
        width:100%;
        height:100%;

        /deep/.el-carousel__container{
            height:540px;
        }
        /deep/.el-carousel__item{
            height:100%;
            cursor: pointer;
        }

        /deep/.el-carousel__arrow{
            width:50px;
            height:100px;
            font-size: 36px;
            border-radius:5px ;
        }
        /deep/.el-carousel__arrow--left{
            left:100px;
        }
        /deep/.el-carousel__arrow--right{
            right:100px;
        }
        /deep/.el-carousel__indicators{
            bottom:100px;
        }
        .banner-item{
            position: relative;
            width: 100%;
            height:540px;
            margin:0 auto;
        }
        
    }
}
</style>