<template>
    <div class="default-wrap">
        
        <div class="my-self-wrap">

            <div class="my-self-left-aside">
                <div class="item-panel-box user-info">
                    <div class="user-avatar">
                        <el-avatar :size="64" :src="avatar"></el-avatar>
                    </div>
                    <div class="user-name">
                        {{nickName}}
                    </div>
                    <div class="">
                        <el-button size="small" class="layout-btn" @click="layOut">退出登录</el-button>
                    </div>
                </div>
                <div class="item-panel-box">
                    <el-menu
                        router 
                        class="my-self-menus"
                        :default-active="$route.path"
                        >
                        <el-menu-item :index="item.path" v-for="(item,index) in myMenus" :key="index" to="">
                            <span slot="title">{{item.title}}</span>
                        </el-menu-item>
                    </el-menu>
                </div>
            </div>
            <div class="my-self-right">
                <router-view/>
            </div>
        </div>


        <!-- 兴趣选择弹框 -->
        <el-dialog 
        width="720px"
        :visible.sync="dialogInterestVisible"
        :close-on-click-modal="false"
        :show-close="false"
        class="interest-diglog"
        append-to-body>
            
            <div class="interest-content">
                <div class="interest-title">
                    关注你感兴趣的领域
                </div>
                <div class="interest-sub-tit">
                    智能推荐,快速找你需要的产品,减少你的时间成本。 
                </div>

                <div class="categorys-list">
                    <el-checkbox-group v-model="cateChecked"  @change="handleCheckedChange">
                        <div class="cate-item" v-for="(cates,index) in categorys" :key="index">
                            <div class="cate-title">{{cates.name}}</div>
                            <div class="cate-sub-list">
                                <el-checkbox :label="item.id" border class="sub-cate-chk"  v-for="(item,index2) in cates.nodes" :key="index2">{{item.name}}</el-checkbox>
                             
                            </div>
                        </div>
                    </el-checkbox-group>

                </div>
            </div>

            <div slot="footer" class="dialog-footer clearfix">
                <el-button @click="close">跳过</el-button>
                <el-button type="primary" @click="confirm" :disabled="confirmBtn || cateChecked.length==0">确定 ( 已选择 {{cateChecked.length}} )</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import uni from '@/services/UNIService.js'
import GoodsCategroyService from '@/services/GoodsCategroyService.js'
import StatisticsRankingService from '@/services/StatisticsRankingService.js'
import Config from "@/common/Config";

export default{
    
    components:{
        
    },
    data(){
        return {

            nickName:'',
            avatar:'',
            myMenus:[
                {
                    index:'1',
                    title:'我的订单',
                    path:'/my/myOrders'
                },{
                    index:'2',
                    title:'我的创新劵',
                    path:'/my/myCoupons'
                },{
                    index:'3',
                    title:'地址管理',
                    path:'/my/myAddress'
                },{
                    index:'4',
                    title:'意见反馈',
                    path:'/my/feedback'
                },{
                    index:'5',
                    title:'客服热线',
                    path:'/my/costomerservice'
                },
            ],
            dialogInterestVisible:false,
            categorys:[],
            cateChecked:[],
            confirmBtn:false
        }
    },
    created(){
        // this.userAuth=await this.checkUserAuth();
        this.nickName=uni.getStorageSync('nickName') || '昵称';
        this.avatar=uni.getStorageSync('avatar') || require('../../assets/img/default_avatar.png');


        // this.open();
        let KeywordRanking = 'UserGoodsCategoryId_' + Config.getBusinessTradingAreaId() + '_' + uni.getStorageSync('userId');
        this.statisticsRanking(KeywordRanking,0,100);
    },
    methods:{

        layOut(){

            this.$confirm('确定要退出登录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                uni.clearStorageSync();

                this.$router.push({
                    path:'/home'
                })
                this.$message({
                    type: 'success',
                    message: '已退出登录'
                });

            }).catch(() => {
                      
            });
            
        },

        // 查询兴趣
        async statisticsRanking(key, value, score) {
            var res = await StatisticsRankingService.statisticsRanking(key, value, score);
     
            if (res.status == 1 && res.data.length == 0) {
                this.open();
            } else {
                // console.log(res.data);
                var catelist = res.data.map((obj) => {return obj.value}).join(",");
                uni.setStorageSync('categoryIds',catelist==10?null:catelist)
                // this.hotgoods = {
                //     tradingAreaId: Config.getBusinessTradingAreaId(),
                //     sellStatus: 1,
                //     // reviewStatus: 1,
                //     sortExp: "complexValue desc",
                //     categoryIds : list==10?null:list
                // }
            }
        },
        // 打开弹框
        open(){
            this.dialogInterestVisible=true;
            this.doSearchTreeByGroup();
        },
        // 获取分类兴趣所有选择
        async doSearchTreeByGroup() {
            var res = await GoodsCategroyService.doSearchTreeByGroup();
            if (res.status == 1) {
               
                this.categorys=res.data;
                // console.log('兴趣分类',res.data);
                // if(res.data){
                //     let category=res.data.find(item => {
                //         //定义一个index,index接收我们找到的全部数据
                //         return item.id == this.categoryId
                //         //当我们里面循环的值和我们要找的值相等的时候返回
                //     })
                   
                // }

            }
        },
        // 获取兴趣分类
        handleCheckedChange(){
            // console.log('已选择兴趣',this.cateChecked)
        },

        async doAdd(key, value, score) {
            var res = await StatisticsRankingService.doAdd(key, value, 100);
            if (res.status == 1) {
            
            }
        },
        // 关闭兴趣弹框
        close(){
            this.dialogInterestVisible=false;
            let KeywordRanking = 'UserGoodsCategoryId_' + Config.getBusinessTradingAreaId() + '_' + uni.getStorageSync('userId');
            this.doAdd(KeywordRanking , 10)
        },
        confirm(){
            this.confirmBtn=true;
            let KeywordRanking = 'UserGoodsCategoryId_' + Config.getBusinessTradingAreaId() + '_' + uni.getStorageSync('userId');
            this.cateChecked.forEach((item,index)=>{
                this.doAdd(KeywordRanking,item)
            })
            this.dialogInterestVisible=false;
            this.confirmBtn=false;
            // console.log('已选择兴趣',this.cateChecked)
        }
    }
}
</script>
<style lang="scss" scoped>
    .my-self-wrap{
        min-width: 1100px;
        width: 1100px;
        position: relative;
        margin:68px auto 40px;
        display: flex;
        padding:15px;
        .my-self-left-aside{
            width:200px;
            margin:0 15px 0 0;

            .item-panel-box{
                margin:0 0 15px;
                border-radius: 4px;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                overflow: hidden;
            }

            .user-info{
                padding:30px 30px 25px;
                // height:190px ;

                .user-name{
                    // height: 25px;
                    line-height: 30px;
                }

                .layout-btn{
                    margin-top: 15px;
                    width:100%;
                }

            }

            .my-self-menus{
                .el-menu-item{
                    text-align: left;
                    height: 42px;
                    line-height: 42px;
                    margin:2px 0;
                    color:#666;

                    &.is-active{
                        border-left: 2px solid #409EFF;
                        color:#333;
                        font-weight: 600;
                    }
                }

            }

        }
        .my-self-right{
            width: calc(100% - 215px);
        }
    }

    .interest-diglog{
    

        /deep/.el-dialog{
            border-radius: 8px;
            overflow:hidden ;
        }

        /deep/.el-dialog__header{
            padding:0 0 0;
        }
        /deep/.el-dialog__body{
        padding:0 0 0;
        }
        /deep/.el-dialog__footer{
            padding:0 0 30px;
        }
    
        .interest-content{
            padding:20px 40px;
            .interest-title{
                height: 50px;
                line-height: 50px;
                font-size: 28px;
                color:#222;
                font-weight:600;
            }

            .interest-sub-tit{
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                color:#333;
            }
            .categorys-list{

                .cate-item{
                    margin:0 0 5px 0;

                    .cate-title{
                        height:50px;
                        line-height:50px;
                        font-size:20px;
                        font-weight: 600;
                    }

                    /deep/.el-checkbox__input{
                        display: none;
                    }
                    /deep/.el-checkbox__label{
                            font-size:16px;
                            padding-left:0;
                    }
                    .el-checkbox{
                        height: 38px;
                        line-height: 38px;
                        padding:0 15px;
                        background: #e5e5e5;
                        margin-right:10px;
                    }

                    /deep/.el-checkbox.is-checked{
                        background: #409EFF;
                        color:#fff;
                        font-weight:600;

                        .el-checkbox__label{
                            color:#fff;
                        }
                    }


                }

                    

            }


        }

        .dialog-footer{
            text-align:center;

            .el-button{
                height:38px;
                line-height:38px;
                padding:0 15px;
            }
        }
    }
</style>