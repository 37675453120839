<template>
    <div class="default-wrap">
      
        <div class="body-content-wrap main-home-wrap">
            <!-- banner -->
            <!-- <div class="banner-box">
                <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="(item,index) in banners" :key="index" >
                        <div class="banner-item" :style="{  backgroundImage:'url(' + item.imgUrl + ')',backgroundPosition: 'center bottom',backgroundRepeat:'no-repeat',backgroundSize:'cover' }" @click="toPath2(3)"></div>
                    </el-carousel-item>
                </el-carousel>
            </div> -->
            <!-- <suHomeBanner></suHomeBanner> -->
            <suDataCenter></suDataCenter>

            <!-- service -->
            <div class="service-box">
                <div class="common-title">
                    <div class="title-top">
                        产品及服务范围
                    </div>
                    <h3>PRODUCT AND SERVICE SCOPE</h3>
                </div>

                <div class="service-content clearfix">
                    <div class="content-wrap service-list">
                        <div class="service-item" v-for="(item,index) in navList" :key="index" @click="toPath(item)">
                            <div class="service-icon">
                                <img :src="item.img" :title="item.name" :alt="item.name">
                            </div>
                            <div class="service-title">
                                {{item.name}}
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>

            <!-- recommend -->
            <div class="recommend-box" :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/recommend_bg.png)',backgroundPosition: 'center center',backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
                <div class="common-title">
                    <div class="title-top">
                        热门推荐
                    </div>
                    <h3>TOP PICKS</h3>
                </div>

                <div class="content-wrap recommend-instrument">
                    <el-carousel :interval="5000" arrow="always">
                        <el-carousel-item v-for="(instruments,index) in recommendGoods" :key="index">
                           <div class="recommend-item">
                               <div class="instrument-item" v-for="(item,index2) in instruments" :key="index2" @click="toGoodsDetail(item.id)">
                                   <div class="instrument-img">
                                       <el-image
                                       style="width:255px;height:270px;"
                                        :src="item.showUrl"
                                        :alt="item.title"
                                        fit="contain"></el-image>
                                       <img v-if="item.discountStatus" class="conpon-img" mode="widthFix" src="https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/coupon_icon.png" />
                                   </div>
                                   <div class="instrument-title">
                                       {{item.title}}
                                   </div>
                                   <div class="instrument-price">
                                       价格 : <span class="price"> <span style="font-size:16px;"> ¥ </span>{{item.presentPrice}}</span> {{item.unitName}}
                                   </div>
                                   <div class="instrument-desc">
                                       <div v-html="highlight(item.goodsDesc)"></div>
                                   </div>
                                   <div class="instrument-more">
                                       <el-button type="primary" round size="small">点击查看</el-button>
                                   </div>
                                 
                               </div>
                           </div>
                        </el-carousel-item>
                    </el-carousel>
                    
                </div>
            </div>

            <!-- news -->
            <div class="news-box">
                <div class="common-title">
                    <div class="title-top">
                        新闻
                    </div>
                    <h3>NEWS</h3>
                </div>

                <!-- <div class="content-wrap news-list">
                    <div class="news-item" v-for="(item,index) in NewsList" :key="index" @click="goDetail(index)">
                        <div class="news-img">
                            <el-image
                            style="width: 210px; height: 184px"
                            :src="item.showPicUrl"
                            :title="item.title"
                            fit="cover"></el-image>
                        </div>
                        <div class="new-detail">
                            <div class="news-title">
                                {{item.title}}
                            </div>
                            <div class="news-other">
                                <div class="news-author">
                                    {{item.author}}
                                </div>
                                <div class="news-date">
                                    {{item.time}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <su-news-box title="新闻列表" :categoryId="categoryId" :count="3"></su-news-box>

            </div>

            <!-- Partner -->
            <div class="partner-box">
                <div class="common-title">
                    <div class="title-top">
                        合作伙伴
                    </div>
                    <h3>COOPERATIVE PARTNER</h3>
                </div>

                <div class="content-wrap partner-list">
                    <div class="partner-item" v-for="(item,index) in partners" :key="index" >
                        <el-image
                        style="width:250px;height:140px;"
                        :src="item.imgUrl"
                        alt="item.title"
                        fit="contain"></el-image>
                    </div>
                </div>
            </div>

            <!-- <el-backtop target=".default-wrap" right="40" bottom="100" :visibilityHeight="400">
                <div><i class='el-icon-top'></i> </div>
            </el-backtop> -->

        </div>
        <layoutFooter></layoutFooter>

        
    </div>
</template>

<script>
import layoutFooter from '@/components/layout/footer.vue'
import suNewsBox from '@/components/su-news-box/su-news-box.vue'
import suHomeBanner from '@/components/su-home-banner/su-home-banner.vue'
import suDataCenter from '@/components/su-data-center/su-data-center.vue'

// import Pageing from "../../mixins/Paging";
import GoodsService from '../../services/GoodsService';
import ArticleService from '../../services/ArticleService.js'
import TimeUtil from '../../common/TimeUtil.js'
import CommonUtil from '../../common/Common';
import Config from '../../common/Config.js'
import uni from '../../services/UNIService';

export default{
    
    mixins: [],
    components:{
        layoutFooter,
        suNewsBox,
        suHomeBanner,
        suDataCenter
    },
    data(){
        return {
           
            
            navList: [{
                id: 1,
                name: '人工智能',
                value: '212001000000000000',
                img: require('../../assets/img/service_icon_1.png')
            }, {
                id: 2,
                value: '212002000000000000',
                name: '激光光电',
                img: require('../../assets/img/service_icon_2.png')
            }, {
                id: 3,
                name: '生物医药',
                value: '212003000000000000',
                img: require('../../assets/img/service_icon_3.png')
            }, {
                id: 4,
                value: '212004000000000000',
                name: '智能制造',
                img: require('../../assets/img/service_icon_4.png')
            }, {
                id: 5,
                value: '212007000000000000',
                name: '节能环保',
                img: require('../../assets/img/service_icon_5.png')
            }, {
                id: 6,
                name: '检验检测',
                value: '212006000000000000',
                img: require('../../assets/img/service_icon_6.png')

            }, {
                id: 7,
                name: '创新券申领',
                img: require('../../assets/img/service_icon_7.png')
            }, {
                id: 8,
                name: '创新训练营',
                img: require('../../assets/img/service_icon_8.png')
            }, {
                id: 9,
                name: '需求广场',
                img: require('../../assets/img/service_icon_9.png')
            }, {
                id: 10,
                name: '新闻公告',
                img: require('../../assets/img/service_icon_10.png')
            }],



            // 推荐
            recommendGoods:[
                // [
                //     {
                //        imgUrl: require('../../assets/img/instrument_1.png'),
                //        title:'热分析系统',
                //        manufacturer:'美国PE公司',
                //        pattern:'PYRIS L DSC/0.2UW',
                //        type:'委托分析、测试服务',
                //        address:'江苏省南京市鼓楼区'      
                //     }
                // ],[
                //     {
                //        imgUrl: require('../../assets/img/instrument_1.png'),
                //        title:'热分析系统',
                //         manufacturer:'美国PE公司',
                //        pattern:'PYRIS L DSC/0.2UW',
                //        type:'委托分析、测试服务',
                //        address:'江苏省南京市鼓楼区'      
                //     }
                // ]
            ],

            // 推荐产品
            // categoryId:248000000000000000,
            categoryId:'',

            // 合作伙伴
            partners:[
                {
                    imgUrl:require('../../assets/img/partner_1.png'),
                    title:'南京因果'
                },{
                    imgUrl:require('../../assets/img/partner_2.png'),
                    title:'南京人工智能高等研究院'
                },{
                    imgUrl:require('../../assets/img/partner_3.png'),
                    title:'南京因华鼎纳米技术研究院'
                },{
                    imgUrl:require('../../assets/img/partner_4.png'),
                    title:'南京申威光电技术研究院'
                },{
                    imgUrl:require('../../assets/img/partner_5.png'),
                    title:'南京邮电信息产业技术研究院'
                },{
                    imgUrl:require('../../assets/img/partner_6.png'),
                    title:'NLALI'
                },{
                    imgUrl:require('../../assets/img/partner_7.png'),
                    title:'南京生物信息研究院'
                },{
                    imgUrl:require('../../assets/img/partner_8.png'),
                    title:'AWSDI'
                },{
                    imgUrl:require('../../assets/img/partner_9.png'),
                    title:'70'
                },{
                    imgUrl:require('../../assets/img/partner_10.png'),
                    title:'THE FUTURE OF POSSIBLE'
                },{
                    imgUrl:require('../../assets/img/partner_11.png'),
                    title:'南京智慧计算研究院'
                },{
                    imgUrl:require('../../assets/img/partner_12.png'),
                    title:'unknow'
                }
            ]

        }
    },
    created(){
        this.categoryId=248000000000000000;
        // uni.setStorageSync('nickName', "刘红磊");
        // uni.setStorageSync('unionId', "oOcXu07k4lm7P3igueFzEiwl9X7g");
        // // uni.setStorageSync('token', "db768015c1bc11ea90f74de60a21111f");
        // uni.setStorageSync('token', "0b8a970f612811eb9efed55d66c5b74b");
        // // uni.setStorageSync('userId', "2c933e6b732c6d5c017332a823c511c6");
        // uni.setStorageSync('userId', "2c93f408774767a60177476c57130010");
        // uni.setStorageSync('bindGroupName', "刘红磊");

        // this.hotgoods = {
        //     tradingAreaId: Config.getBusinessTradingAreaId(),
        //     sellStatus: 1,
        //     // reviewStatus: 1,
        //     sortExp: "complexValue desc"
        // };
        // this.pCategoryId = '212000000000000000';

        // await this.checkUserAndGetSessionKey();
        // this.init(),
        this.doPage()
    },
    mounted(){
         
    },
    methods:{

        // 推荐产品
        async doPage(){
            let hotGoodsSearch = {
                tradingAreaId: Config.getBusinessTradingAreaId(),
                sellStatus: 1,
                reviewStatus: 1,
                sortExp: "complexValue desc"
            }

            if(uni.getStorageSync('categoryIds')){
                hotGoodsSearch.categoryIds=uni.getStorageSync('categoryIds')==10?null:uni.getStorageSync('categoryIds');
            }

            let res = await GoodsService.doPageBySearchModel(hotGoodsSearch,1,6);
            // console.log(datas);

            if(res.data && res.data.length>0){
                let datas=this.dataFilter(res.data);
                let recommendGoods=[];
                if(datas){
                    // console.log(datas.length)
                    let num=Math.ceil(datas.length/3)
                    for(let i=0;i<num;i++){
                        recommendGoods.push(datas.slice(i*3,(i+1)*3));
                    }
                    console.log(recommendGoods);
                }
                this.recommendGoods=recommendGoods;
            }
        },
        dataFilter(goodss) {
            goodss.forEach(function (goods) {
                if(goods.showUrl) {
                    goods.showUrl = goods.showUrl.split(',')[0];
                } else {
                    goods.showUrl = 'https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/goods_default.png'
                }
            });
            return goodss;
        },
        highlight (item) {
            if(item){
                 return item.replace(/<[^>]+>/g, ' ')// 去掉所有的html标记
            }else{
                return '暂无描述';
            }
            // const removehtml = (str = '') => str.replace(/<[/!][^<>]>/gi, '')
        },
        toPath2(value){
            this.$router.push({
                name:'mall',
                params:{
                    type:value
                }
            });
        },
        toPath(item) {
            switch (item.id) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    this.$router.push({
                        name:'mall',
                        params:{
                            categoryId:item.value
                        }
                    });
                    break;
                case 7:
                    //创新券
                    this.$router.push({
                        path:'/coupon',
                    });
                    break;
                case 8:
                    // uni.navigateTo({
                    //     url: '/pages/News/NewsList?type=1'
                    // })
                    break;
                case 9:
                    // this.resultContent = '即将上线，敬请期待~'
                    // this.resultModalShow = true
                    //需求广场
                    break;

                case 10:
                    this.$router.push({
                        path:'/news',
                    });
                    //公告栏
                    break;
            }
        },
        toGoodsDetail(id){
            this.$router.push({
                path:'/mall/pdtDetail',
                query:{
                    goodsId:id
                }
            });
        },

        search() {
            uni.navigateTo({
                    url: '/pages/Search/Search'
                })
        },
        toList() {
            uni.navigateTo({
                url: '../CouponGoodsList/CouponGoodsList'
            })
        },
        change(index) {
            this.current = index;
        },

        changeTab(e) {
            if (e == this.goodsCurrentIndex) return
            this.goodsCurrentIndex = e
            this.goodsIndex = 0
            this.goodsList = []
            if (e == 1) {
                this.goodsQueryId = 'tab_feed_smart_1'
            } else if (e == 2) {
                this.goodsQueryId = 'tab_feed_electric_2'
            } else if (e == 3) {
                this.goodsQueryId = 'tab_feed_life_3'
            } else if (e == 4) {
                this.goodsQueryId = 'tab_feed_fashion_4'
            } else if (e == 5) {
                this.goodsQueryId = 'tab_feed_coupon_5'
            } else {
                this.goodsQueryId = ''
            }
            this.changeTabLoading = true
            this.getGoodsData('initial')
            this.loadingStatus = 'loading'
        },

        /*下拉刷新的回调, 有三种处理方式:*/
        downCallback() {
            this.mescroll.endSuccess();
        },
        /*上拉加载的回调*/
        upCallback(page) {
            setTimeout(() => {
                this.mescroll.endByPage(10, 20);
            }, 2000)
        },
        /**
         * 菜单导航滚动
         */
        ScrollMenu(e) {
            let scrollLeft = e.target.scrollLeft;
            const query = uni.createSelectorQuery().in(this);
            query.select('.nav').boundingClientRect(data => {
                let wid = e.target.scrollWidth - data.width - (data.left * 2 + 5);
                this.slideNum = (scrollLeft / wid * 300) / 2;
            }).exec();
        },
        /**
         * 搜索点击
         */
        onSearch() {
            uni.navigateTo({
                url: '/pages/search/search'
            })
        },
        /**
         * 扫一扫点击
         */
        onCode() {
            // 只允许通过相机扫码
            uni.scanCode({
                onlyFromCamera: true,
                success: function(res) {
                    console.log('条码类型：' + res.scanType);
                    console.log('条码内容：' + res.result);
                }
            });
        },
        /**
         * 分类点击
         * @param {Object} item
         * @param {Number} index
         */
        onClassify(item, index) {
            this.classifyShow = index;
        },
        /**
         * 跳转点击
         * @param {String} type 跳转类型
         */
        onSkip(type) {
            switch (type) {
                case 'menu':
                    uni.navigateTo({
                        url: '/pages/SearchGoodsList/SearchGoodsList'
                    })
                    break;
                case 'inform':
                    break;
                case 'flash':
                    uni.navigateTo({
                        url: '/pages/FlashSale/FlashSale'
                    })
                    break;
                case 'GoodChoice':
                    uni.navigateTo({
                        url: '/pages/GoodChoice/GoodChoice'
                    })
                    break;
                case 'goods':
                    uni.navigateTo({
                        url: '/pages/GoodsDetails/GoodsDetails',
                        animationType: 'zoom-fade-out',
                        animationDuration: 200
                    })
                    break;
            }
        },
        to(item) {
            switch (item.id) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    uni.navigateTo({
                        url: '/pages/Search/Search?categoryId=' + item.value
                    })
                    break;
                case 7:
                    uni.switchTab({
                        url: '/pages/InnovateCoupon/InnovateCoupon'
                    })
                    //创新券
                    break;
                case 8:
                    uni.navigateTo({
                        url: '/pages/News/NewsList?type=1'
                    })
                    break;
                case 9:
                    this.resultContent = '即将上线，敬请期待~'
                    this.resultModalShow = true

                    //需求广场
                    break;

                case 10:
                    uni.navigateTo({
                        url: '/pages/News/NewsList'
                    })
                    //公告栏
                    break;
            }
        },


    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    }
}
</script>

<style lang="scss" scoped>
    .main-home-wrap{
        position: relative;
        min-width: 1100px;

        

        // 服务
        .service-box{
            padding-bottom:30px;
            .common-title{
                .title-top{
                    margin-top:0;
                }
            }

            .service-content{
                margin-top:20px;

                .content-wrap{
                    width:1100px;
                    margin:0 auto;
                    
                }

                .service-list{
                    display: flex;
                    height: 300px;
                    width:1100px;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    
                    .service-item{
                        position: relative;
                        display: inline-block;
                        width: 160px;
                        height:140px;
                        padding:20px 25px 10px;
                        margin:0 30px;
                        cursor: pointer;
                        
                        -webkit-transition:all 6s ease-out;
                        -moz-transition:all .6s ease-out;
                        transition:all .6s ease-out;
                        border-radius: 8px;
               
                        img{
                            width:60px;
                        }
                        .service-title{
                            margin-top:16px;
                        }
                    }

                    .service-item:hover{
                        -webkit-transition:all 6s ease-out;
                        -moz-transition:all .6s ease-out;
                        transition:all .6s ease-out;
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                        transform:translateY(-4px);
                        
                    }
                }
            }
        }

        // 推荐
        .recommend-box{
            width:100%;
            height:800px;
            margin-top: 30px;
            padding: 20px 0 40px;
            position: relative;

           .common-title{
                .title-top{
                    color:#fff;
                }
                .title-top::before{
                    color: #fff;
                    background: #fff;
                    margin-left: -130px;

                }
                .title-top::after{
                    color: #fff;
                    background: #fff;
                    margin-left: 105px;

                }
                h3{
                    color:#eee;
                }
            }

            .recommend-instrument{
                margin-top:20px;
                /deep/.el-carousel__container{
                    height:600px;
                }
                /deep/.el-carousel__arrow{
                    width:50px;
                    height:100px;
                    font-size: 48px;
                    font-weight:bold;
                    border-radius:5px ;
                    background: transparent;
                }
                

                .recommend-item{
                    display: flex;
                    justify-content: flex-start;
                    padding:10px 92.5px;
                    height:550px;
                  


                    .instrument-item{
                        background: #fff;
                        border-radius: 20px;
                        padding:10px;
                        margin:0 15px;
                        width: 275px;
                        height:527px;
                        cursor: pointer;

                        .instrument-img{
                            position: relative;
                            width:255px;
                            height:270px;
                            background: #fefefe;
                            border-radius: 15px;
                            .el-image{
                                position:relative ;
                            }

                            .conpon-img{
                                position: absolute;
                                top:10px;
                                right:10px;
                                width:80px;
                            }
                            
                        }
                        .instrument-title{
                            margin: 20px 0 10px;
                            // height: 40px;
                            line-height: 32px;
                            font-size: 22px;
                            color:#333;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                        .instrument-price{
                             font-size:16px;
                            color:#999;
                            height: 30px;
                            line-height: 30px;
                            margin-bottom: 15px;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;

                            .price{
                                font-size: 24px;
                                color:#e61414;
                                font-weight:800px;
                            }
                        }
                        .instrument-desc{
                            color:#999;
                            font-size:16px;
                            line-height:26px;
                            text-align:left;
                            min-height:78px;
                            overflow : hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                        .instrument-manufacturer,.instrument-pattern,.instrument-type,.instrument-address{
                            font-size:16px;
                            color:#999;
                            height: 30px;
                            line-height: 30px;
                        }
                        .instrument-more{
                            margin-top:10px;
                            /deep/.el-button{
                                background: #2b87fe;
                                width:90px;
                                height: 30px;
                                line-height:28px;
                                padding:0 15px;
                            }
                        }

                    }
                }
            }
            
 
        }

        // 新闻
        .news-box{
            padding-top: 20px;

            .common-title{
          
                .title-top::before{
                    margin-left: -100px;
                }
                .title-top::after{
                    margin-left: 75px;
                }
  
            }

        }
        
        // 合作伙伴
        .partner-box{
            padding-top: 20px;
            padding-bottom:40px;

            .common-title{
                .title-top::before{
                    margin-left: -130px;
                }
                .title-top::after{
                    margin-left: 105px;
                }
            }

           .partner-list{
                margin-top: 50px;
                height:507px;
                display:flex;
                flex-wrap: wrap;
                justify-content: space-between;

               .partner-item{
                 width:265px;
                 margin-bottom: 15px;
                 border-radius:5px;
                 padding:5px 7.5px;
                 box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                 cursor: pointer;
                  
                  img{
                      width: 100%;
                  }
               }
           }   

        }

    }
</style>