<template>
    <div class="footer clearfix">
        <div class="footer-wrap clearfix">
            <div class="footer-left left">
                <div class="site-info">
                    <div class="info-item" v-for="(item,index) in infos" :key="index">
                        <div class="info-icon" >
                            <img :src="item.icon" :alt="item.icon">
                        </div>
                        <div class="info-txt">
                            {{item.text}}
                        </div>

                    </div>
                   
                </div>
                <div class="ewm">
                    <img :src="ewm" alt="微信小程序">
                    <div class="txt">
                        微信小程序
                    </div>
                </div>
            </div>

            <div class="footer-right right">
                <el-form ref="form" :model="form" class="footer-form">
                    <el-form-item class="form-info">
                        <el-col :span="12" class="col-left">
                         <el-input v-model="form.phone" placeholder="请输入你的手机号" size=
                         "small"></el-input>
                        </el-col>
                        <el-col :span="12" class="col-right">
                         <el-input v-model="form.email" placeholder="请输入你的邮箱" size=
                         "small"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item >
                        <el-input type="textarea" v-model="form.content" placeholder="留言:" class="form-content" :autosize="{ minRows: 7, maxRows: 7}"></el-input>
                    </el-form-item>
                    <el-form-item class="form-sub">
                        <el-button @click="onSubmit" size="small">提交</el-button>
                    </el-form-item>
                    </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return {
                infos:[
                    {icon:require('../../assets/img/info_location.png'),text:'南京市栖霞区兴智路6号兴智科创园'},
                    {icon:require('../../assets/img/info_tel.png'),text:'025-85770521'},
                    {icon:require('../../assets/img/info_mail.png'),text:'1457584847@qq.com'},
                ],

                ewm:require('../../assets/img/ewm.jpg'),

                form: {
                    phone: '',
                    email: '',
                    content: '',
                }
            }
        },

        methods: {
            onSubmit() {
                console.log('submit!');
            }
        }
    }
</script>


<style lang="scss" scoped>
    .footer{
        position: relative;
        width: 100%;
        height: 400px;
        background: #2b87fe;
        padding-top:60px;

        .footer-wrap{
            width:1100px;
            margin:0 auto;

            .footer-left{
                width: 400px;

                .site-info{
                    .info-item{
                        display: flex;
                        color:#fff;
                        margin-bottom: 15px;
                    }

                    .info-icon{
                        margin-right:15px;
                        width: 32px;
                        img{
                            width: 100%;
                        }
                    }
                    .info-txt{
                        height:32px;
                        line-height: 32px;
                        font-size: 16px;
                    }

                }

                .ewm{
                    text-align: left;
                    width:120px;
                    img{
                        width: 120px;
                    }

                    .txt{
                        height: 20px;
                        line-height: 20px;
                        font-size: 14px;
                        color: #fff;
                        text-align: center;
                    }
                }
            }

            .footer-right{
                width:calc(100% - 400px);
                
                .footer-form{

                    .form-info{
                        margin-bottom: 12px;
                        .col-left{
                            padding-right:15px;
                        }
                        .col-right{
                            padding-left:15px;
                        }
                    }

                    
                
                    .form-sub{
                        
                        text-align: right;
                        .el-button{
                            color:#2b87fe;
                        }
                            
                    }
                }
            }

        }
    }
</style>