<template>
  <div class="page404"  >
    <div class="i404">
      <img :src="require('../../assets/img/404.png')"/>
      <!-- <p>您的访问页面可能被删除或者不存在</p> -->
      <a href="/">返回首页</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
  .page404{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

 
    .i404{
        width: 900px;
        height: 260px;
        text-align: center;
   
      img{
        width: 400px;
        // height: 200px;
      }
      p{
        height:40px;
        line-height: 40px;
        font-size: 20px;
        color: #3d95ff;
        margin-top: 30px;
      }
      a{
        display: block;
        width: 150px;
        margin: 0 auto;
        height: 40px;
        color: #ffffff;
        background: #56a9ff;
        margin-top: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 50px;
        font-size: 18px;
      }
    }
    
   
  }

</style>