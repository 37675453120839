import http from '../common/Request'
const StatisticsRankingService = {
  async doAdd(key,value,score) {
    var res = await http({
      url: "tatisticsRanking_doAdd.do",
      method: "POST",
      data: {
        key:key,
        value:value,
        score:score
      }
    })
    return res.data.data
  },
  async statisticsRanking(key,start,end) {
    var res = await http({
      url: "tatisticsRanking_reverseRangeWithScores.do",
      method: "POST",
      data: {
        key:key,
        start:start,
        end:end
      }
    })
    return res.data
  }
  
}
export default StatisticsRankingService