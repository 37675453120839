import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import './router/permission.js';
import User from './mixins/User.js'

const echarts = require('echarts');
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.mixin(User);






new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
