/* eslint-disable indent */
/**
 * Created by Admin on 2017/10/9.
 */
import http from '../common/Request'
import uni from '../services/UNIService.js'
import config from '../common/Config.js'

const UserService = {
	async login() { //登录，获取用户信息
		let token = uni.getStorageSync("token");
		let nickName = uni.getStorageSync("nickName");
		let userId = uni.getStorageSync("userId");
		let unionId = uni.getStorageSync("unionId");
		if (token && token.length > 3 && userId) {
			return true
		}
		let loginRes = await this.doGetLoginCode(); //获取用于交换用户信息的code
		// #ifdef APP-PLUS || H5
		console.log(loginRes.data.token);
		this.setUserStorageSync(loginRes.data.id, loginRes.data.nickName, loginRes.data.token, loginRes.data.unionid,
			loginRes.data.gradeNum);
		return true;
		// #endif

		// #ifdef MP-WEIXIN
		// let res = await this.doWXALogin(config.getWXAAppId(), config.getBusinessTradingAreaId(), loginRes.code);
		// if (res.status != 1) {
		// 	return false
		// }

		this.setUserStorageSync(res.data.id, res.data.nickName, res.data.loginToken, res.data.unionid, res.data.gradeNum);
		// #endif
		return true
	},
	setUserStorageSync(userId, nickName, token, unionId, gradeNum) { //缓存用户信息
		uni.setStorageSync('userId', userId);
		uni.setStorageSync('nickName', nickName);
		uni.setStorageSync('token', token);
		uni.setStorageSync('unionId', unionId);
		uni.setStorageSync('gradeNum', gradeNum);
	},
	async checkBind() {
		var bindGroup = null;
		// var bindToken = uni.getStorageSync("bindToken");
		// var bindGroupId = uni.getStorageSync("bindGroupId");
		// var userId = uni.getStorageSync("userId");
		// var bindUserId = uni.getStorageSync("bindUserId");
		// var bindGroupName = uni.getStorageSync("bindGroupName");
		// var account = uni.getStorageSync("account");
		// if (bindToken && bindUserId && bindGroupId && userId && account) {
		// 	bindGroup = {
		// 		bindToken: bindToken,
		// 		id: bindGroupId,
		// 		name: bindGroupName,
		// 		bindUserId: bindUserId,
		// 		account:account
		// 	};
		// 	return bindGroup;
		// }
		var userBindRelation = await this.getDefaultUserBindRelation();
		if (userBindRelation) {
			this.setBindStorage(userBindRelation);
			bindGroup = {
				bindToken: userBindRelation.bindUser.token,
				id: userBindRelation.bindUser.companyId,
				name: userBindRelation.groupName,
				bindUserId: userBindRelation.userId,
				account:userBindRelation.bindUser.account
			};
			return bindGroup;
		} else {
			return null;
		}
	},
	async doGetLoginCode() { //获取app端用于交换用户信息的code
		// #ifdef MP-WEIXIN
		let [error, res] = await uni.login();
		return res;
		// #endif

		// #ifdef APP-PLUS || H5
		// var res = await http({
		// 	url: "openuser_doTmpLogin.do",
		// 	method: "POST",
		// 	data: {}
		// })
		return res.data
		// #endif
	},
	async doWXALogin(appId, groupId, code) {
		var res = await http({
			url: "openWeixin_doWXALogin.do",
			method: "POST",
			data: {
				appId: appId,
				groupId: groupId,
				code: code
			}
		})
		console.log(res.data);
		if (res.data.status == -1) {
			let loginRes = await this.doGetLoginCode();
			res = await this.doWXALogin(appId, groupId, loginRes.code);
			return res;
		}
		return res.data;
	},
	async doAppLogin(account, password) {
		var res = await http({
			url: "openuser_doAppLogin.do",
			method: "POST",
			data: {
				account: account,
				password: password,
				noDes: 1
			}
		})
		if (res.data.status == 1) {
			var loginUser = res.data.data;
			uni.setStorageSync('token', loginUser.loginToken);
			uni.setStorageSync('userId', loginUser.id);
			uni.setStorageSync('nickName', loginUser.nickName);
			uni.setStorageSync('unionId', loginUser.unionid);
		}
		return res.data;
	},
	async doUpdateByWXA(userInfo) {
		var res = await http({
			url: "user_doUpdateByWXA.do",
			method: "POST",
			data: {
				groupId: config.getBusinessTradingAreaId(),
				appId: config.getWXAAppId(),
				imUserInfo: userInfo,
				encryptedData: userInfo.encryptedData,
				iv: userInfo.iv
			}
		});
		return res.data;
	},
	async doUpdateByAPP(userInfo) {
		var res = await http({
			url: "user_doUpdateByWXA.do",
			method: "POST",
			data: {
				groupId: config.getBusinessTradingAreaId(),
				appId: config.getAppAppId(),
				imUserInfo: userInfo,
				encryptedData: userInfo.encryptedData,
				iv: userInfo.iv
			}
		});
		return res.data;
	},
	async doGetMyInfo() { // 获取登录用户的信息
		var res = await http({
			url: 'nadminuser_doGetMyInfo.do',
			method: 'POST'
		});
		this.setUserStorageSync(res.data.data.id, res.data.data.nickName, res.data.data.token, res.data.data.unionid, res.data
			.data.gradeNum);
		return res.data;
	},
	async refreshSessionKey() {
		var res = await this.doGetLoginCode();
		console.log("refreshSessionKey:" + res);
		if (res.code) {
			//发起网络请求
			var loginRes = await http({
				url: 'openWeixin_doWXALogin.do',
				method: 'POST',
				data: {
					appId: config.getWXAAppId(),
					groupId: config.getBusinessTradingAreaId(),
					code: res.code
				}
			});
			var loginUser = loginRes.data.data;
			uni.setStorageSync('token', loginUser.loginToken);
			uni.setStorageSync('userId', loginUser.id);
			uni.setStorageSync('nickName', loginUser.nickName);
			uni.setStorageSync('unionId', loginUser.unionid);
			return loginRes.data;
		}
	},
	async doGetUserById(userId) {
		var res = await http({
			url: 'user_doGetUserById.do',
			method: 'POST',
			data: {
				userId: userId
			}
		})
		return res.data
	},
	async doGetPhoneNumber(encryptedData, iv) {
		var res = await http({
			url: 'openWeixin_doGetPhoneNumber.do',
			method: 'POST',
			data: {
				encryptedData: encryptedData,
				iv: iv
			},
		})
		return res.data
	},
	async doUpdateContactsInfo(param) {
		var res = await http({
			url: 'user_doUpdateContactsInfo.do',
			method: 'POST',
			data: param,
		})
		return res.data
	},
	checkUserAuth() {
		var nickName = uni.getStorageSync("nickName");
		if (nickName && nickName != '') {
			return true;
		} else {
			return false;
		}
	},
	async getGradeNum() {
		var gradeNum = uni.getStorageSync("gradeNum");
		if (gradeNum) {
			return gradeNum;
		} else {
			// await this.doGetMyInfo();
			gradeNum = uni.getStorageSync("gradeNum");
			return gradeNum;
		}
	},
	async doGenPosterImage() {
		var res = await http({
			url: 'user_doGenPosterImage.do',
			method: 'POST',
			data: {
				groupId: config.getBusinessTradingAreaId()
			}
		});
		return res.data;
	},
	async doTransferUserData(fromUserId, toUserId) {
		var transferRes = await http({
			url: 'nadminuser_doTransferUserData.do',
			method: 'POST',
			data: {
				fromUserId: fromUserId,
				toUserId: toUserId
			}
		});
		console.log(transferRes);
		if (transferRes.data.status == 1) {
			console.log("transferRes.data.status ==1");
			var transferUser = transferRes.data.data;
			uni.setStorageSync('token', transferUser.loginToken);
			if (transferUser.token) {
				uni.setStorageSync('token', transferUser.token);
			}
			uni.setStorageSync('userId', transferUser.id);
			uni.setStorageSync('nickName', transferUser.nickName);
			uni.setStorageSync('unionId', transferUser.unionid);
		}
	},
	async doGetUserWXInfo(e) { //e:微信open-type="getUserInfo"传入的值
		console.log(e);
		if (e.detail.errMsg == "getUserInfo:ok") { //如果用户点击了允许授权所做的操作
			var userInfo = e.detail.userInfo;
			userInfo.headimgurl = userInfo.avatarUrl;
			userInfo.nickname = userInfo.nickName;
			userInfo.sex = userInfo.gender;
			userInfo.encryptedData = e.detail.encryptedData;
			userInfo.iv = e.detail.iv;
			console.log('userInfo=')
			console.log(userInfo);
			console.log({
				groupId: config.getShopGroupId(),
				imUserInfo: userInfo,
				appId: config.getWXAAppIdShop(),
				encryptedData: userInfo.encryptedData,
				iv: userInfo.iv
			})
			var res = await this.doUpdateByWXA(userInfo);
			console.log('doUpdateByWXA')
			console.log(res);
			if (res.status == 1) {
				uni.setStorageSync('nickName', userInfo.nickname);
				uni.setStorageSync('unionId', res.data.unionid);
				uni.setStorageSync('token', res.data.token);
				uni.setStorageSync('userId', res.data.id);
			}
			return res
		}
	},
	async doGetQRCodeById(id) {
		var res = await http({
			url: 'qrcodeOpen_dogetQRCodeById.do',
			method: 'POST',
			data: {
				id: id
			}
		});
		return res.data;
	},
	async setBindStorage(userBindRelation) {
		uni.setStorageSync('account', userBindRelation.bindUser.account);
		uni.setStorageSync('bindToken', userBindRelation.bindUser.token);
		uni.setStorageSync('bindUserId', userBindRelation.bindUserId);
		uni.setStorageSync('bindGroupId', userBindRelation.bindUser.companyId);
		uni.setStorageSync('bindGroupName', userBindRelation.groupName);
	},
	async getDefaultUserBindRelation() {
		var res = await http({
			url: 'user_doGetDefaultUserBindRelation.do',
			method: 'POST'
		});
		return res.data.data;
	},
	async doUpdateBindUser(userId, bindUserId) { //更改默认绑定
		var res = await http({
			url: 'user_doUpdateBindUser.do',
			method: 'POST',
			data: {
				userId: userId,
				bindUserId: bindUserId
			}
		})
		return res.data;
	},
	async doBindUser(account, password, bindToken) { //绑定员工用户
		var res = await http({
			url: 'user_doBindUser.do',
			method: 'POST',
			data: {
				account: account,
				password: password,
				bindToken: bindToken
			}
		})
		return res.data
	},
	async doUnbindUser(userId, account, password) { //解除绑定
		var res = await http({
			url: 'user_doUnbindUser.do',
			method: 'POST',
			data: {
				userId: userId,
				account: account,
				password: password
			}
		})
		return res.data
	},
	async doSearchUserBindRelation(userId) { //查询用户绑定的员工
		var res = await http({
			url: 'user_doSearchUserBindRelation.do',
			method: 'POST',
			data: {
				userId: userId,
			}
		})
		return res.data
	},
	async doUpdateUserInfo(user) { //查询用户绑定的员工
		var res = await http({
			url: 'user_doUpdateUserInfo.do',
			method: 'POST',
			data: {
				user: user,
			}
		})
		return res.data
	},
	async doScanQRCodeLogin() { //扫码获取登录二维码
		var res = await http({
			url: 'openuser_doScanQRCodeLogin.do',
			method: 'POST',
			data: {
				wxaAppId: 'wx9ca27283c755dc24',
				path: 'pages/Demand/Demand',
			}
		})
		return res.data
	},
	async doGetQRCodeLoginResult(loginKey) { // 获取扫码登录结果
		// loginKey='5576c3119db511ebb4e8bb8fcb0039f0';
		var res = await http({
			url: 'openuser_doGetQRCodeLoginResult.do',
			method: 'POST',
			data: {
				loginKey: loginKey,
			}
		})
		return res.data
	}

}

export default UserService
