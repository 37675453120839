var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
var PI = 3.1415926535897932384626;
var a = 6378245.0;
var ee = 0.00669342162296594323;
const LocationUtil = {
  /**
   * 百度坐标系 (BD-09) 与 火星坐标系 (GCJ-02)的转换
   * 即 百度 转 谷歌、高德
   * @param bd_lon
   * @param bd_lat
   * @returns {{lng: number, lat: number}}
   */
  bd09togcj02 (bd_lon, bd_lat) {
    var x_pi = 3.14159265358979324 * 3000.0 / 180.0;
    var x = bd_lon - 0.0065;
    var y = bd_lat - 0.006;
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
    var gg_lng = z * Math.cos(theta);
    var gg_lat = z * Math.sin(theta);
    return {longitude: gg_lng, latitude: gg_lat}
  },
  /**
   *火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换
   * 即谷歌、高德 转 百度
   * @param lng
   * @param lat
   * @returns {{longitude: number, latitude: number}}
   */
  gcj02tobd09 (lng, lat) {
    var z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI);
    var theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI);
    var bd_lng = z * Math.cos(theta) + 0.0065;
    var bd_lat = z * Math.sin(theta) + 0.006;
    return {longitude: bd_lng, latitude: bd_lat}
  },
  /**
       * WGS84转GCj02
       * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换
       * @param lng
       * @param lat
       * @returns {*[]}
       */
      wgs84togcj02tobd09 (lng, lat) {
        const xPI = 3.14159265358979324 * 3000.0 / 180.0
        const PI = 3.1415926535897932384626
        const a = 6378245.0
        const ee = 0.00669342162296594323
        // WGS84转GCj02
        let dlat = this.transformlat(lng - 105.0, lat - 35.0)
        let dlng = this.transformlng(lng - 105.0, lat - 35.0)
        let radlat = lat / 180.0 * PI
        let magic = Math.sin(radlat)
        magic = 1 - ee * magic * magic
        let sqrtmagic = Math.sqrt(magic)
        dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
        dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
        let mglat = lat + dlat
        let mglng = lng + dlng
        // 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换
        let z = Math.sqrt(mglng * mglng + mglat * mglat) + 0.00002 * Math.sin(mglat * xPI)
        let theta = Math.atan2(mglat, mglng) + 0.000003 * Math.cos(mglng * xPI)
        let bdlng = z * Math.cos(theta) + 0.0065
        let bdlat = z * Math.sin(theta) + 0.006
        // return [bdlng, bdlat]
        return {longitude: bdlng, latitude: bdlat}
      },

   
  //判断是否在国内，在中国国内的经纬度才需要做偏移
   out_of_china(lng, lat) {
      return (
          lng < 72.004 ||
          lng > 137.8347 ||
          (lat < 0.8293 || lat > 55.8271 || false)
      );
  },
  //转化经度
   transformlng(lng, lat) {
      var ret =
          300.0 +
          lng +
          2.0 * lat +
          0.1 * lng * lng +
          0.1 * lng * lat +
          0.1 * Math.sqrt(Math.abs(lng));
      ret +=
          ((20.0 * Math.sin(6.0 * lng * PI) +
              20.0 * Math.sin(2.0 * lng * PI)) *
              2.0) /
          3.0;
      ret +=
          ((20.0 * Math.sin(lng * PI) +
              40.0 * Math.sin((lng / 3.0) * PI)) *
              2.0) /
          3.0;
      ret +=
          ((150.0 * Math.sin((lng / 12.0) * PI) +
              300.0 * Math.sin((lng / 30.0) * PI)) *
              2.0) /
          3.0;
      return ret;
  },

 //转化纬度
  transformlat(lng, lat) {
     var ret =
         -100.0 +
         2.0 * lng +
         3.0 * lat +
         0.2 * lat * lat +
         0.1 * lng * lat +
         0.2 * Math.sqrt(Math.abs(lng));
     ret +=
         ((20.0 * Math.sin(6.0 * lng * PI) +
             20.0 * Math.sin(2.0 * lng * PI)) *
             2.0) /
         3.0;
     ret +=
         ((20.0 * Math.sin(lat * PI) +
             40.0 * Math.sin((lat / 3.0) * PI)) *
             2.0) /
         3.0;
     ret +=
         ((160.0 * Math.sin((lat / 12.0) * PI) +
             320 * Math.sin((lat * PI) / 30.0)) *
             2.0) /
         3.0;
     return ret;
 },


   //wgs84 to gcj02   地球坐标系 转 火星坐标系
    wgs84_to_gcj02(lng, lat) {
      if (this.out_of_china(lng, lat)) {
          return [lng, lat];
      } else {
          var dlat =this.transformlat(lng - 105.0, lat - 35.0);
          var dlng =this.transformlng(lng - 105.0, lat - 35.0);
          var radlat = (lat / 180.0) * PI;
          var magic = Math.sin(radlat);
          magic = 1 - ee * magic * magic;
          var sqrtmagic = Math.sqrt(magic);
          dlat =
              (dlat * 180.0) /
              (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
          dlng =
              (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
          var mglat = lat + dlat;
          var mglng = lng + dlng;
   
          return [mglng, mglat];
      }
	  },
  
 /**
   * GCJ02 转换为 WGS84
   * @param lng
   * @param lat
   * @returns {*[]}
   */
  gcj02towgs84(lng, lat) {
    if (this.out_of_china(lng, lat)) {
      return [lng, lat]
    }
    else {
      var dlat = this.transformlat(lng - 105.0, lat - 35.0);
      var dlng = this.transformlng(lng - 105.0, lat - 35.0);
      var radlat = lat / 180.0 * PI;
      var magic = Math.sin(radlat);
      magic = 1 - ee * magic * magic;
      var sqrtmagic = Math.sqrt(magic);
      dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
      dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
      var mglat = lat + dlat;
      var mglng = lng + dlng;
      return [lng * 2 - mglng, lat * 2 - mglat]
    }
  },
    getRad(d){
        var PI = Math.PI;
        return d*PI/180.0;
    },
    getDistance(lat1,lng1,lat2,lng2){
        var f = this.getRad((lat1 + lat2)/2);
        var g = this.getRad((lat1 - lat2)/2);
        var l = this.getRad((lng1 - lng2)/2);
        var sg = Math.sin(g);
        var sl = Math.sin(l);
        var sf = Math.sin(f);
        var s,c,w,r,d,h1,h2;
        var a = 6378137.0;//The Radius of eath in meter.
        var fl = 1/298.257;
        sg = sg*sg;
        sl = sl*sl;
        sf = sf*sf;
        s = sg*(1-sl) + (1-sf)*sl;
        c = (1-sg)*(1-sl) + sf*sl;
        w = Math.atan(Math.sqrt(s/c));
        r = Math.sqrt(s*c)/w;
        d = 2*w*a;
        h1 = (3*r -1)/2/c;
        h2 = (3*r +1)/2/s;
        s = d*(1 + fl*(h1*sf*(1-sg) - h2*(1-sf)*sg));
        s = s/1000;
        s = s.toFixed(2);//指定小数点后的位数。
        return s;
    }
}
export default LocationUtil
