<template>
    <div class="banner-box">
        <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="(item,index) in 1" :key="index" >
                <!-- <div class="banner-item" :style="{  backgroundImage:'url(' + item.imgUrl + ')',backgroundPosition: 'center bottom',backgroundRepeat:'no-repeat',backgroundSize:'cover' }" @click="toPath2(3)"></div> -->


                <div class="swiper-item" :style="{  backgroundImage:'url('+require('../../assets/img/banner_data_bg.png')+')',backgroundPosition: 'center top',backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
					
					<div class="main-tit" :style="{  backgroundImage:'url('+require('../../assets/img/banner_tit_bg.png')+')',backgroundPosition: 'center center',backgroundRepeat:'no-repeat',backgroundSize:'100%' }">
						运营数据实时概览
					</div>
					<div class="charts-box" >
						<!-- 商家统计指标 -->
						<div class="charts-item charts-1"  :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/charts_bg.png)',backgroundPosition: 'center top',backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
							<div class="charts-title">商家统计指标</div>
							<div class="chart">
                                <div id="myChart" :style="{width: '280px', height: '290px'}"></div>
							</div>
						</div>
						
						<!-- 领券情况 -->
						<div class="charts-item charts-2"  :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/charts_bg.png)',backgroundPosition: 'center top',backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
							<div class="charts-title">领券情况</div>
							<div class="sub-tit"> <span style="color:#00ced1;">{{totalAmount}}</span> <span style="font-size: 18rpx;">元</span></div>
							<div class="chart">
                                <div id="myChart2" :style="{width: '280px', height: '260px'}"></div>
							</div>
						</div>
						<!-- 用券情况 -->
						<div class="charts-item charts-3"  :style="{  backgroundImage:'url(https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/charts_bg.png)',backgroundPosition: 'center top',backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
							<div class="charts-title">用券情况</div>
							<div class="consumption-total">
								<div class="total-item">
									<div class="tit">用券总额(元)</div>
									<div class="value" style="color:#00ced1;">{{consumeAmount}}</div>
								</div>
								
								<div class="total-item">
									<div class="tit">合同总额(元)</div>
									<div class="value"  style="color:#ffd700">{{totalPrice}}</div>
								</div>
							</div>
							<div class="sub-title">用券商品排行</div>
							<div class="table">
								<div class="th">
									<div class="td td-1">排名</div>
									<div class="td td-2">商品名称(元)</div>
									<div class="td td-3">成交金额(元)</div>
								</div>
								<div class="tr" v-for="(item,index) in goodsCustomRankng" :key="index">
									<div class="td td-1">{{index+1}}</div>
									<div class="td td-2">{{item.goodsName}}</div>
									<div class="td td-3">{{item.realPrice}}</div>
								</div>
								
								
							</div>  
						</div>
						
					</div>
				</div>
            
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
	import UserService from '../../services/UserService';
	// import AreaService from '../../services/AreaService';
	// import MarketingSceneService from '../../services/MarketingSceneService';
	import DataCenterService from '../../services/DataCenterService';
	import ConfigUtil from '../../common/Config';
	export default {
		
		data() {
			return {
				
				legendColor: [
					"#1890FF",
					"#91CB74",
					"#FAC858",
					"#EE6666",
					"#73C0DE",
					"#3CA272",
					"#FC8452",
					"#9A60B4",
					"#ea7ccc"
				],

				
				
				totalAmount:0,  // 领劵总额
				consumeAmount:0,  // 用卷总额
				totalPrice:0, //合同总额

				groupCount:[], // 商家数量
				
				//商品排行
				goodsCustomRankng:[
					{goodsName:'软件开发服务',realPrice:'28.5万'},
					{goodsName:'高性能服务集群',realPrice:'20万'},
					{goodsName:'基于无线信号的手势识别软件开发',realPrice:'10万'},
				]  // 商品用卷排行
			};
		},
		watch: {
			
		},
		mounted(){
            this.drawMixCharts();
            this.init();
        },
		methods: {
            // 混合图
            drawMixCharts(){
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.$echarts.init(document.getElementById('myChart'))
                // 绘制图表
                myChart.setOption({
                    textStyle:{
                        color:"#fff"
                    },
                    color: [
                        "#1890FF",
                        "#91CB74",
                        "#FAC858",
                        "#EE6666",
                        "#73C0DE",
                        "#3CA272",
                        "#FC8452",
                        "#9A60B4",
                        "#ea7ccc"
                    ],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    grid:{
                        left: 60 ,
                        top: 70 ,
                        right: 50 ,
                        bottom: 50 ,
                    },
                    // toolbox: {
                        // feature: {
                        //     dataView: {show: true, readOnly: false},
                        //     magicType: {show: true, type: ['line', 'bar']},
                        //     restore: {show: true},
                        //     saveAsImage: {show: true}
                        // }
                    // },
                    legend: {
                        data: ['成交额', '商家数量'],
                        align:'left',
                        left:0,
                        textStyle:{
                            color:'#fff'
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ["人工智能", "激光光电", "生物医药", "智能制造", "检验检测", "节能环保"],
                            axisPointer: {
                                type: 'shadow'
                            },
                            nameTextStyle:{
                                color:'#fff'
                            },
                            // axisLine:{
                            //     lineStyle:{
                            //         color:'#fff'
                            //     }
                            // },
                            axisLabel:{
                                rotate:45,
                                fontSize:10
                            }
                            
                        }
                        
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '成交额',
                            min: 0,
                            max: 250,
                            interval: 50,
                            axisLabel: {
                                formatter: '{value} w元'
                            },
                            splitLine:{
                                lineStyle:{
                                    type:"dashed"
                                }
                            }
                        },
                        {
                            type: 'value',
                            name: '商家数量',
                            min: 0,
                            max: 25,
                            interval: 5,
                            axisLabel: {
                                formatter: '{value} 个'
                            },
                            splitLine:{
                                lineStyle:{
                                    type:"dashed"
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: '成交额',
                            type: 'bar',
                            data: [135.5, 100.5, 87.5, 78.5, 60.5, 48.5]
                        },
                        {
                            name: '商家数量',
                            type: 'line',
                            yAxisIndex: 1,
                            data: this.groupCount
                        }
                    ]
                    
                });
            },
            // 饼图数据
            drawPieCharts(datas){
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.$echarts.init(document.getElementById('myChart2'))
                // 绘制图表
                myChart.setOption({
                    textStyle:{
                        color:"#fff"
                    },
                    color: [
                        "#1890FF",
                        "#91CB74",
                        "#FAC858",
                        "#EE6666",
                        "#73C0DE",
                        "#3CA272",
                        "#FC8452",
                        "#9A60B4",
                        "#ea7ccc"
                    ],
                    tooltip: {
        trigger: 'item'
                    },
                    legend: {
                        orient: 'horizontal',
                        left: 'left',
                        bottom:0,
						itemWidth:20,
						itemHeight:10,
						itemStyle:{
							borderWidth:1
						},
						textStyle:{
							color:'#fff'
						}
                    },
                    series: [
                        {
                            name: '领券情况',
                            type: 'pie',
                            radius: '50%',
                            data: datas,
                            // data: [
                            //     {value: 1048, name: '搜索引擎'},
                            //     {value: 735, name: '直接访问'},
                            //     {value: 580, name: '邮件营销'},
                            //     {value: 484, name: '联盟广告'},
                            //     {value: 300, name: '视频广告'}
                            // ],
                            itemStyle:{
                                borderWidth:2,
                                borderColor:'#fff',
                                borderCap:'round',
                                // borderRadius:3
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                    
                });
            },

			// 截取字符
			autoAddEllipsis(pStr, pLen) {  
			    var _ret = this.cutString(pStr, pLen);  
			    var _cutFlag = _ret.cutflag;  
			    var _cutStringn = _ret.cutstring;  
			  
			    if ("1" == _cutFlag) {  
			        return _cutStringn + "...";  
			    } else {  
			        return _cutStringn;  
			    }  
			},
			  
			/* 
			 * 取得指定长度的字符串 
			 * 注：半角长度为1，全角长度为2 
			 *  
			 * pStr:字符串 
			 * pLen:截取长度 
			 *  
			 * return: 截取后的字符串 
			 */  
			cutString(pStr, pLen) {    
			    // 原字符串长度  
			    var _strLen = pStr.length;    
			    var _tmpCode;    
			    var _cutString;    
			    // 默认情况下，返回的字符串是原字符串的一部分  
			    var _cutFlag = "1";    
			    var _lenCount = 0;    
			    var _ret = false;    
			    if (_strLen <= pLen/2) {  
			        _cutString = pStr;  
			        _ret = true;  
			    }    
			    if (!_ret) {  
			        for (var i = 0; i < _strLen ; i++ ) {  
			            if (this.isFull(pStr.charAt(i))) {  
			                _lenCount += 2;  
			            } else {  
			                _lenCount += 1;  
			            }    
			            if (_lenCount > pLen) {  
			                _cutString = pStr.substring(0, i);  
			                _ret = true;  
			                break;  
			            } else if (_lenCount == pLen) {  
			                _cutString = pStr.substring(0, i + 1);  
			                _ret = true;  
			                break;  
			            }  
			        }  
			    }  
			      
			    if (!_ret) {  
			        _cutString = pStr;  
			        _ret = true;  
			    }  
			  
			    if (_cutString.length == _strLen) {  
			        _cutFlag = "0";  
			    }  
			  
			    return {"cutstring":_cutString, "cutflag":_cutFlag};  
			},
			  
			/* 
			 * 判断是否为全角 
			 *  
			 * pChar:长度为1的字符串 
			 * return: true:全角 
			 *          false:半角 
			 */  
			isFull (pChar) {  
			    if ((pChar.charCodeAt(0) > 128)) {  
			        return true;  
			    } else {  
			        return false;  
			    }  
			},

			init(){
				this.doStatisticalTotalAmountGroupType();
				// this.doStatisticalReducePriceAmountGroupGoods();
				this.doGetConsumeAmount();
				this.doGetConsumeAmount2();
				this.doSumTotalPrice();
				this.doGetCategoryGroupCount();
                
			},
			// 商品分类的商家排行
			async doGetCategoryGroupCount(){
				let search={
					tradingAreaId:ConfigUtil.getBusinessTradingAreaId(),
					fCategoryId:'212000000000000000',
					deleteStatus:0,
					reviewStatus:1,
					sellStatus:1,

				}
				let res=await DataCenterService.doGetCategoryGroupCount(search);
				// console.log('doGetCategoryGroupCount',res)
				if(res.status==1){
					let datas=[];

					for(let i=0;i<res.data.length;i++){
						datas.push(res.data[i]['count'])
					}
					this.groupCount=datas;
				}

				this.drawMixCharts();
			},

			// 领劵总额
			async doGetConsumeAmount2(){
				let couponLog={
					logType:1,
					groupId:'2c93f40876be5e9e0176cc60649366ce'
				}
				let res=await DataCenterService.doGetConsumeAmount(couponLog);
				
				if(res.status==1){
					this.totalAmount=res.data;
				}
			},
			// 合同总额
			async doSumTotalPrice(){
				let orderSearchModel={
					tradingAreaId:'2c93f40876be5e9e0176cc60649366ce',
					orderType:1,
					orderStatuss:'2,3,4'
				}
				let res=await DataCenterService.doSumTotalPrice(orderSearchModel);
				
				if(res.status==1){
					this.totalPrice=res.data;
				}
			},
			
			// 用卷总额
			async doGetConsumeAmount(){
				let orderSearchModel={
					orderType:1,
					tradingAreaId:'2c93f40876be5e9e0176cc60649366ce',
					orderStatuss:'2,3,4',
					payStatus:1
				}
				let res=await DataCenterService.doGetConsumeCouponAmount(orderSearchModel);
				
				if(res.status==1){
					this.consumeAmount=res.data;
				}
			},
			// 商品用卷排行
			async doStatisticalReducePriceAmountGroupGoods(){
				let orderSearchModel={
					orderType:1,
					orderStatuss:'2,3,4',
					orderItem:{
						refundStatus:1,
						startRealPrice:0
					},
					tradingAreaId:'2c93f40876be5e9e0176cc60649366ce',
				}
				let res=await DataCenterService.doStatisticalReducePriceAmountGroupGoods(orderSearchModel);
				
				if(res.status==1){
					this.goodsCustomRankng=res.data.slice(0,3);
				}
			},
			// 领劵情况
			async doStatisticalTotalAmountGroupType(){
				let couponLog={
					logType:1,
					groupId:ConfigUtil.getBusinessTradingAreaId(),
					couponType:88,
				}
				let res=await DataCenterService.doStatisticalTotalAmountGroupType(couponLog);
				let datas=[];
				// console.log('222res',res);
				if(res.status==1){
					for(let i=0;i<res.data.length;i++){
						// let legend=['初创人才项目','成熟科技项目','其他项目','青年大创项目'];
						// let name=this.autoAddEllipsis(res.data[i]['groupName'],6);
						// let name=legend[i];
						if(res.data[i]['groupName']){
							datas.push({name:res.data[i]['groupName'],value:res.data[i]['reimburseAmount']});
						}
						
					}
					// datas.push({name:'其他项目',value:0})
				}
				
                this.drawPieCharts(datas);
			
			},
			
		}
	};
</script>



<style scoped lang="scss">
	
.banner-box{
    position:relative;
    width: 100%;
    margin:0 0 0;

    max-height: 540px;
    overflow: hidden;

    .el-carousel{
        width:100%;
        height:100%;

        /deep/.el-carousel__container{
            height:540px;
        }
        /deep/.el-carousel__item{
            height:100%;
            cursor: pointer;
        }

        /deep/.el-carousel__arrow{
            width:50px;
            height:100px;
            font-size: 36px;
            border-radius:5px ;
        }
        /deep/.el-carousel__arrow--left{
            left:100px;
        }
        /deep/.el-carousel__arrow--right{
            right:100px;
        }
        /deep/.el-carousel__indicators{
            bottom:60px;
        }

        .swiper-item {
			// padding: 0 30rpx;
			width: 100%;
            margin:0 auto;
            padding-top:80px;
			padding-bottom:10px;
			// height: 245rpx;
			// overflow: hidden;
			box-sizing: border-box;
			// border-radius:10px;
			// padding:10rpx 0;
		}
		
		.main-tit{
			width: 980px;
			margin: 0 auto ;
			// margin-top: 40px;
			height: 40px;
			line-height: 34px;
			font-size: 24px;
			text-align: center;
			color:#fff;
		}
		
		.charts-box{
			width: 980px;
			margin: 0 auto;
			// background: rgba(255,255,255,0.5);
			display: flex;
			justify-content: space-around;
			padding:10px 0;
			border-radius: 10px;
			position: relative;
			z-index: 100;
			
			.charts-item{
				width: 310px;
				padding:15px 15px 10px;
				min-width: 180px;
				height: 360px;
				// background-color: #efefef;
			
				border-radius: 10px;
				
				.charts-title{
                    
					box-sizing: border-box;
					font-size: 20px;
					padding:0 0 4px;
					color: #fff;
                    text-align: left;
				}
				
				.chart{
					// height: 240px;
					box-sizing: border-box;
				}
			}
			.charts-1{
				.chart{
                    margin-top: 10px;
					box-sizing: border-box;
				}
				.legend-list{
					margin: 8px 0;
					display: flex;
					flex-wrap: wrap;
					.legend-item{
						display: flex;
						align-items: center;
						width: 45%;
						.legend-icon{
							width:12px;
							height:6px;
							margin-right: 8px;
						}
						.legend-txt{
							font-size: 12px;
							color:#fff;
						}
					}
				}
			}
		
			.charts-2{
				.chart{
					// height: 180px;
					box-sizing: border-box;
				}
				.sub-tit{
					// padding-left: 10rpx;
                    text-align: left;
					font-size: 20px;
					margin-top: 10px;
					color:#fff;
					font-weight:800;
				}
				.legend-list{
					display: flex;
					flex-wrap: wrap;
					.legend-item{
						display: flex;
						align-items: center;
						width: 50%;
						margin-bottom: 4px;
						.legend-icon{
							width:12px;
							height:6px;
							margin-right: 8px;
						}
						.legend-txt{
							font-size: 12px;
							color:#fff;
						}
					}
				}
			}
			.charts-3{
				.consumption-total{
					margin-top: 12px;
					display: flex;
					box-sizing: border-box;
					
					.total-item{
						display: inline-block;
						width: 50%;
						box-sizing: border-box;
						overflow: hidden;
                        text-align: left;
						
						.tit{
                            
							font-size: 16px;
							box-sizing: border-box;
							color: #fff;
						}
						.value{
							font-size: 20px;
							box-sizing: border-box;
							color: #fff;
							font-weight:800;
						}
						
					}
					
				}
				.sub-title{
					margin-top: 16px;
					font-size: 20px;
					box-sizing: border-box;
					color: #fff;
					
				}
				.table{
					margin-top: 12px;
					box-sizing: border-box;
					padding:0 0;
					.th,.tr{
						background-color: rgb(99,109,232);
						display: flex;
						margin:0 0 5px 0;
						
						.td{
							box-sizing: border-box;
							padding:0 4px;
							height: 28px;
							line-height: 28px;
							font-size: 16px;
							color:#fff;
							overflow: hidden;
						}
						.td-1{
							width: 24%;
						}
						.td-2{
							width: 38%;
						}
						.td-3{
							width: 38%;
						}
					}
				}
				
			}
		}
		

        
        
    }
}

	$baseColor:#ff5959;

</style>
