import http from '../common/Request'
import Config from '../common/Config.js'
const ArticleService = {
    async doPage(categoryId, groupId, page, count) { // 获取商户余额
        var res = await http({
            // url: "wxnews_doPage.do",
            url: "openwxnews_doPage.do",
            method: "POST",
            data: {categoryId:categoryId,belong:groupId,page:page, count:count}
        });
        return res.data;
    },
    async doGet(articleId) { // 获取商户余额
        var res = await http({
            url: "wxnews_dogetNewsById.do",
            method: "POST",
            data: {id:articleId}
        });
        return res.data.data;
    },
	async doGetNewsCategory(type) { // 查询资讯分类
	    var res = await http({
	        // url: "newscategory_doSearchTreeJson.do",
	        url: "openNewscategory_doSearchTreeJson.do",
	        method: "POST",
	        data: {type:2,nc:{
				belong:Config.getBusinessTradingAreaId()
			}}
	    });
	    return res.data;
	},
	async doGetNews(categoryId,page,count) { // 查询资讯新闻
	    var res = await http({
	        // url: "wxnews_doPage.do",
	        url: "openwxnews_doPage.do",
	        method: "GET",
	        data: {type:2,categoryId:categoryId,belong:Config.getBusinessTradingAreaId(),page:page,count:count}
	    });
	    return res.data;
	}
}
export default ArticleService