var extConfig =  {
    "extEnable": true,
    "extAppid": "wx9ca27283c755dc24",
    "ext": {
        "businessTradingAreaId": "2c93f40876be5e9e0176cc60649366ce",
        "slipperTradingAreaId":"",
        "rootTradingAreaId":"2c93f40876be5e9e0176cc5c104666c1",
        "wxghId":"gh_bb3d70cbbad0",
        "wxAppId": "wx0a0841e948f09709",
        "appAppId":"",
        "wxaAppId":"wx9ca27283c755dc24",
        "path":"https://www.suconnected.com/",
        // "path":"https://swdev.suconnected.com/",
        "businessTradingAreaNo":"2666",
        "rootTradingAreaNo":"2665"
    }
}
const config = {	// 接口前缀
    baseUrl:'http://douban-api.gek6.cn',
    // 图片前缀
    imgPreUrl:'',
    // 无图时默认图片
    imgDefault:'',
    // 在微信浏览器下 是否获取code
    get_wx_code:false,
    /* 授权方式 静默授权 手动授权
        snsapi_base 不弹出授权页面，直接跳转，只能获取用户openid
        snsapi_userinfo 弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息
    */
    wx_code_type:'snsapi_userinfo',
    /* 配置 */
    OSS_endpoint:"oss-cn-beijing.aliyuncs.com", // OSS地址
    OSS_bucket:"xxx",// oss 仓库名
    oss_host:'https://xxx.oss-cn-beijing.aliyuncs.com/', // 客户端直传时的地址
    // OSS最高权限的 秘钥 H5端 用不到
    oss_accesskey:'xxxxxxx',
    oss_accessId:'xxxxx',
    getPath() {
        return extConfig.ext.path;
    },
    getWXAAppId() {
        return extConfig.ext.wxaAppId;
    },
    getRootTradingAreaId() {
        return extConfig.ext.rootTradingAreaId;
    },
    getBusinessTradingAreaId() {
        return extConfig.ext.businessTradingAreaId;
    },
    getSlipperTradingAreaId() {
        return extConfig.ext.slipperTradingAreaId;
    },
    getBusinessTradingAreaNo() {
        return extConfig.ext.businessTradingAreaNo;
    },
    getRootTradingAreaNo() {
        return extConfig.ext.rootTradingAreaNo;
    },
    getAppAppId() {
        return extConfig.ext.appAppId;
    },
    getWXGHId() {
        return extConfig.ext.wxghId;
    },
    getShowType() {
        //通用产品 30
        //虚拟产品 20
        //食品和话费产品 10
        var showType = 30;
        // #ifdef MP-WEIXIN
        const res = uni.getSystemInfoSync();
        if(res.platform == "ios") {
            showType = 30;
        } else {
            showType = 20;
        }
        // #endif
        // #ifdef APP-PLUS
        showType = 10;
        // #endif
        return showType;
    },
    getGroupBusiness(groupType) {
        switch (groupType) {
            case 1:
                return '汽车服务'
                break;
            case 2:
                return '美食'
                break;
            case 3:
                return '普通企业'
                break;
            case 4:
                return '电商企业'
                break;
            case 5:
                return '汽配企业'
                break;
            case 6:
                return '电信客户'
                break;
            case 7:
                return '汽修后市场'
                break;
            case 8:
                return '代理商'
                break;
            case 9:
                return '银行'
                break;
            case 10:
                return '休闲娱乐'
                break;
            case 11:
                return '外卖'
                break;
            case 12:
                return '出行'
                break;
            case 13:
                return '名宿/公寓'
                break;
            case 14:
                return '丽人/美发'
                break;
            case 15:
                return '母婴/亲子'
                break;
            case 16:
                return '旅行'
                break;
            case 17:
                return '机票/火车票'
                break;
            case 18:
                return '时尚购'
                break;
            case 19:
                return '旅拍婚照  '
                break;
            case 20:
                return '生活服务'
                break;
            case 21:
                return '运动健身'
                break;
            case 22:
                return '宠物'
                break;
            case 23:
                return '金融便民服务'
                break;
            case 24:
                return '摄影写真'
                break;
            case 25:
                return '学习培训'
                break;
            case 26:
                return '家居/电器'
                break;
            case 27:
                return '出境游'
                break;
            case 28:
                return '结婚'
                break;
            case 29:
                return '医疗'
                break;
            case 30:
                return '水果生鲜'
                break;
            case 31:
                return 'KTV'
                break;
            case 32:
                return '家饰/家纺'
                break;
            case 33:
                return '零食'
                break;
            case 34:
                return '摄影写真'
                break;
            case 35:
                return '美容养生'
                break;
            case 36:
                return '酒行'
                break;
            case 37:
                return '出行工具'
                break;
            case 38:
                return '奢侈品养护'
                break;
            case 39:
                return '水果/蛋糕'
                break;
            case 101:
                return '商圈'
                break;
            default:
                return '未定义的类型'
        }
    },
    getFormattedRequestData(obj) {
        console.log(obj)
        var query = '';
        var name, value, fullSubName, subName, subValue, innerObj, i;
        for (name in obj) {
            value = obj[name];
            if (value instanceof Array) {
                for (i = 0; i < value.length; ++i) {
                    subValue = value[i];
                    fullSubName = name + '[' + i + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += this.getFormattedRequestData(innerObj) + '&';
                }
            } else if (value instanceof Object) {
                for (subName in value) {
                    subValue = value[subName];
                    if (subValue == null || subValue === "") {
                        continue;
                    }
                    fullSubName = name + '.' + subName + '';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += this.getFormattedRequestData(innerObj) + '&';
                }
            } else if (value !== undefined && value !== null) {
                query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
            }
        }
        console.log(typeof(query))
        return query.length ? query.substr(0, query.length - 1) : query;
    }
}
export default {
    install:function(Vue){
        Vue.prototype.$config = config
    },
    ...config
}
