import http from '../common/Request'
import Config from '../common/Config.js'
const GoodsCategroyService = {
  async doSearchTreeByGroup(groupId) { //获取剩余报销基金或者获取剩余报销基金
    var res = await http({
      url: "/goods/gscategorybrand_doSearchTreeByGroup.do",
      method: "POST",
      data: {
        groupId: Config.getBusinessTradingAreaId()
      }
    })
    return res.data;
  },
  async doSearchByPid(pid) { //分类
    var res = await http({
      url: "/goods/category_doSearchByPid.do",
      method: "POST",
      data: {
        pid: pid
      }
    })
    return res.data.data;
  },
}
export default GoodsCategroyService
