import axios from 'axios'
import config from '../common/Config.js'
import util from '../common/Common'
import UserService from '../services/UserService'
import uni from '../services/UNIService.js'

//import { Message } from 'element-ui'
// import cacheCookies from '@/utils/auth'

export default async function request (options) {
  if (options.headers) {
    if(options.headers["Content-Type"]) {

    } else {
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
  } else {
    options.headers = {
      'Content-Type':'application/x-www-form-urlencoded'
    }
  }

  if(!options.method) {
    options.method = 'POST';
  }
  //options.url = configUtil.getServerDomain() +"/" + options.url;
  // if(options.url.indexOf("http")==-1) {
    // options.url = process.env.BASE_API +"/" + options.url;
    // options.url = config.getPath() + options.url;
  // }

  // if(options.data instanceof Object) {
  //   options.type = 'json';
  //   // options.data.token = options.data.token?options.data.token:cacheCookies.getToken();
  //   options.data.token = options.data.token?options.data.token:uni.getStorageSync('token');
  //   options.data = util.doFormattedRequestData(options.data);
  // }


  if(!options.type || options.type == "json") { //如果设为 json，会尝试对返回的数据做一次 JSON.parse
    if(!options.data) {
      options.data = {};
    }
    options.data.token = options.data.token?options.data.token:uni.getStorageSync('token');
    if(options.bindToken) {
            options.data.token = uni.getStorageSync('bindToken');
    }

    options.data = util.doFormattedRequestData(options.data);
  }else {
    if(!options.data) {
      options.data = "";
    }
        if(options.bindToken) {
            options.data += "&token=" + uni.getStorageSync('bindToken');
        } else {
            options.data += "&token=" + uni.getStorageSync('token');
    }

    options.type = "json";
  }

  options.method = 'POST';
	options.url = config.getPath() + options.url;


  try{
    const response = await axios(options);
    return response
  }catch(err){
    return {
      status: -1,
      data: err.message
    };
  }
}


// export default async function request (options) {
// 	var optionData = options.data;
// 	var url = options.url;
// 	if(options.header) {
// 		options.header['content-type'] = "application/x-www-form-urlencoded";
// 	}else {
// 		options.header = {'content-type':"application/x-www-form-urlencoded"}
// 	}
// 	if(!options.type || options.type == "json") { //如果设为 json，会尝试对返回的数据做一次 JSON.parse
// 		if(!options.data) {
// 			options.data = {};
// 		}
// 		options.data.token = options.data.token?options.data.token:uni.getStorageSync('token');
// 		if(options.bindToken) {
//             options.data.token = uni.getStorageSync('bindToken');
// 		}

// 		options.data = util.doFormattedRequestData(options.data);
// 	}else {
// 		if(!options.data) {
// 			options.data = "";
// 		}
//         if(options.bindToken) {
//             options.data += "&token=" + uni.getStorageSync('bindToken');
//         } else {
//             options.data += "&token=" + uni.getStorageSync('token');
// 		}

// 		options.type = "json";
// 	}
// 	if(options.loading) {
// 		uni.showLoading({
// 			title: options.loading
// 		})
// 	}else {
// 		//uni.showNavigationBarLoading();
// 	}
// 	options.method = 'POST';
// 	options.url = config.getPath() + options.url;
// 	let [error, response] = await uni.request(options);
// 	//console.log(response);
// 	if(options.loading) {
// 		uni.hideLoading()
// 	}else {
// 		//uni.hideNavigationBarLoading();
// 	}
// 	if(response.data.status == -1001) { //未登录状态需要传token
// 		var res = await UserService.login();
// 		options.data = optionData;
//         options.url = url;
// 		if(res) {
// 			return await request(options);
// 		}
// 	}else if(response.data.status !=1) { 
// 		uni.showToast({
// 			title: response.data.data,
// 			icon: 'none',
// 			mask: true,
// 			duration: 2000
// 		});
// 	}
// 	return response;
// }