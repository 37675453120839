<template>
    <div class="content-wrap news-list">
        <div class="news-item" v-for="(item,index) in NewsList" :key="index" @click="goDetail(index)">
            <div class="news-img">
                <el-image
                style="width: 280px; height: 135px"
                :src="item.showPicUrl"
                :title="item.title"
                :alt="item.title"
                fit="cover"></el-image>
            </div>
            <div class="new-detail">
                <div class="news-title">
                    {{item.title}}
                </div>
                <div class="news-other">
                    <div class="news-author">
                        {{item.author}}
                    </div>
                    <div class="news-date">
                        {{item.time}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArticleService from '../../services/ArticleService.js'
import TimeUtil from '../../common/TimeUtil.js'
import Pageing from "../../mixins/Paging";

export default {
  name: 'NewsList',
  // components: {},
  mixins: [Pageing],
  data() {
    return{
      page:1,
     
    }
  },
  props: {
    // title:String,
    // marginlr:{
    //   type: String,
    //   default() {
    //     return "30rpx";
    //   }
    // },
	  categoryId:Number,
	  count:Number
  },
  computed: {
      NewsList() {
          if (this.pageList.length) {
              this.pageList.forEach((item, index) => {
              item.time = TimeUtil.formatSpecificTime(item.creatTime, 'Y-M-D H:m:s')
              })
              return this.pageList
          }
      }
  },
  watch:{
    categoryId(value){
      // console.log("su-item-good-list.vue watch  searchModel change....");
      this.doPage();
    }
  },
  created(){
    this.doPage();
  },
  methods: {
    async doPage() {
      this.pagePlugin = {
          api: ArticleService.doGetNews,
          options: [this.categoryId, this.page, this.count]
      }
      this.initializePageList()
    },

    // async doGetNews(categoryId,page,count) {
    //     var res = await ArticleService.doGetNews(categoryId,page,count);
    //     console.log(res);
    //     if (res.status == 1 && res.data.length) {
    //         res.data.forEach((item, index) => {
    //             item.time = TimeUtil.formatSpecificTime(item.creatTime, 'Y-M-D H:m:s')
    //         })
    //         this.NewsList = res.data;
    //     } else {
    //         this.NewsList = []
    //     }
    // },

    // 新闻详情
    goDetail(index){
        // if(!url){
        //     return false;
        // }
        let art=this.NewsList[index];
        this.$router.push({
            name:'newDetail',
            params:{
                art:art
            }
        });
    },
  },
}
</script>

<style lang="scss" scoped>

.news-list{
    min-height: 600px;
    .news-item{
        display:flex;
        padding:40px 0 40px;
        border-bottom:2px solid #d2d2d2;
        position: relative;
        cursor: pointer;

        .news-img{
            width:280px;
            height:135px;
            margin-right: 20px;
            // background: #eee;
            border-radius: 5px;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        .new-detail{
            width: calc(100% - 235px);
            text-align: left;
            font-size: 24px;
            position: relative;

            .news-title{
                font-weight: bold;
                line-height: 40px;
                color:#333;
            }

            .news-other{
                position: absolute;
                bottom: 0;
                width: 100%;
            }
            .news-author,.news-date{
                color:#999;
                display: inline-block;
                width:50%;
            }
            .news-date{
                text-align: right;
            }

        }
    }

    .news-item:last-child{
        border:none;
    }
}
</style>